// MODULES
import React from "react";
import ReactDOM from 'react-dom';

// CLASSES
import Settings from "@classes/Settings";
import User from "@classes/User";
import Customer from "@classes/Customer";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import NotesDrawer from '@components/NotesDrawer';
import Form from "@components/Form/Form";
import Footer from "./components/Footer/Footer";
import Modal from "@components/Modal/Modal";
import ProgressIndicator from "./components/ProgressIndicator/ProgressIndicator";
import DefaultFields from "./components/DefaultFields/DefaultFields";

// MUI COMPONENTS
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// HELPERS
import {
  connectRedux,
  createClassName,
  createB64Context,
  stringifyQueryParams,
  delay,
  isEmpty,
  formatSSN
} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// REDUCERS
import {actions as appActions} from "@reducers/app";

// VIEWS
import CobFinish from "./views/Finish/Finish";
import ViewMapping from "./views/ViewMapping";
import VMCitroneer from "./views/Citroneer/VMCitroneer";
import VMDemoClient from "./views/DemoClient/VMDemoClient";
import StackLayout from "../../components/layouts/StackLayout/StackLayout";


class CobRoute extends React.PureComponent
	{
		state = {
			isLoading: true,
			hide: true,
			animatingOut: false,
			activeViewIndex: 0,
			canSendCase: false,
			openedDraftModalVisible: false,
			quitSessionModalVisible: false,
      notesDrawerOpen: false,
			showShareDialog: false,
			shareDialogLoading: true,
			sharingCaseId: null,
			sharingData: [],
			shareError: false,
			startView: null,
			signViews: [],
			assentlyComponents: [],
			workflows: [],
			currentWorkflow: "default",

			caseLockedModalVisible: false,
			caseLockTakenModalVisible: false,
			caseLockInfo: null
		};

        formRef = React.createRef();

		defaultFieldValues = _getDefaultFieldValues.call(this);
		tempUser = null;
		tempAdvisor = null;
		intervalTasks = null;
    isReadOnly = false;

		async componentDidMount()
		{
			const {match, history, actions, reducers} = this.props;
			const newAppState = {};

			window.addEventListener('popstate', this.handleBackButton);
			this._isMounted = true;

			// Fetch data used by views in the application
			try
			{
				newAppState.exchangeRates = await _setupExchangeRatesAsync.call(this);
				newAppState.availableAdvisors = await _setupAvailableAdvisorsAsync.call(this);
				newAppState.availableBanks = await _setupAvailableBanksAsync.call(this);
			}
			catch (ignored)
			{
				// this fails if auth token is invalid
				if (ignored !== undefined)
				{
					console.log(ignored);
				}
			}

			if (!match.params.view)
			{
				history.push("/cob/1");
			}

			// If a User is not set, create new instance
			if (!reducers.app.user || !reducers.app.user.data)
			{
				const authToken = Settings.get("AUTH_TOKEN");

				if (authToken)
				{
					try
					{
						const {partner, advisor} = await api("/auth/data");
						if (partner)
						{
							newAppState.user = new User(partner);
							newAppState.advisor = new User(advisor);

							// save user in temp object in the class as well since we had a race condition bug that caused
							// user in reducer to be null when we needed it
							this.tempUser = newAppState.user;
							this.tempAdvisor = newAppState.advisor;
            }
						else
						{
							Settings.clear();
							history.push("/partner-not-found");
							return;
						}
					}
					catch (e)
					{
						if (this._isMounted)
						{
							console.error(e.message);

							if (e.message === "PARTNER_NOT_FOUND")
							{
								Settings.clear();
								history.push("/partner-not-found");
								return;
							}
							else if (e.message === "AUTH_TOKEN_REJECTED" || e.message === "AUTH_TOKEN_UNDEFINED" || e.message === "AUTH_TOKEN_INVALID")
							{
								Settings.clear();
								history.push("/");
								window.location = "/";
								return;
							}
						}
					}
				}
			}
			// If a Customer is not set, create new instance
			if (!reducers.app.customer)
			{
        const {customer, customerState} = await _setupCustomer.call(this);
				newAppState.customer = customer;
				newAppState.customerState = customerState;
			}
			else if (!newAppState.customer)
			{
				newAppState.customer = reducers.app.customer;
			}

			// Set latest customer documents
			this.latestCustomerDocuments = reducers.app.customer ? reducers.app.customer.documents : newAppState.customer.documents;

			if (Object.keys(newAppState).length > 0)
			{
				actions.setAppState(newAppState);
			}

			if (this._isMounted)
			{
				const {partner} = reducers.app;

				/**
				 * Set up the sign view, the final view that is always fixed in position
				 */
				const signViews = [];
				let signView = ViewMapping.availableViews.find(function (v)
				{
					return v.id === partner.signView;
				});

				if (signView == null)
				{
					signView = VMCitroneer.availableViews.find(function (v)
					{
						return v.id === partner.signView;
					});
				}

				if (signView == null)
				{
					signView = VMDemoClient.availableViews.find(function (v)
					{
						return v.id === partner.signView;
					});
				}

				if (signView == null)
				{
					console.log("ERROR :: Signview not configured");
				}
				else
				{
					signViews.push(signView);
				}

				console.log("Checking status of Assently components... ");
				const response = await api("/assently/status");
				let assentlyComponents = [];
				if (response.status !== 'OK')
				{
					assentlyComponents = response.components;
				}

				/**
				 * Set up available sections / views - these make up the form that will be filled in
				 */
				const partnerWorkflows = partner.workflows;
				Object.values(partner.workflows).forEach(workflow =>
				{
					workflow.views = [];

					for (let i = 0; i < workflow.steps.length; i++)
					{
						let view = ViewMapping.availableViews.find(function (v)
						{
							return v.id === workflow.steps[i];
						});

						if (!view)
						{
							view = VMCitroneer.availableViews.find(function (v)
							{
								return v.id === workflow.steps[i];
							});
						}

						if (!view)
						{
							view = VMDemoClient.availableViews.find(function (v)
							{
								return v.id === workflow.steps[i];
							});
						}

						if (!view)
						{
							throw new Error("Invalid view id " + workflow.steps[i]);
						}

						// this is the "page number" of the application, parsed as string
						// Needs to be + 2 since the array is zero based and the first page is always the "start page"
						// Work from a copy of the view since if it is re-used by different workflows it needs to have
						// different viewIndex values. Without a copy the value will be overwritten.
						const copiedView = {...view};
						copiedView.viewIndex = (i + 2) + "";
						workflow.views.push(copiedView);
					}
				});

				const currentCaseId = Settings.get("CASE_ID");
				let currentWorkflow = "default";
				if (currentCaseId)
				{
					currentWorkflow = newAppState?.customer?.workflow;
					if (currentWorkflow === undefined)
					{
						currentWorkflow = reducers.app.customer.workflow;
					}
				}

			    // // vite import fixes, see https://github.com/vitejs/vite/issues/4945
			    // // brute force way
			    // const splitName = partner.startView.split('/');
			    // const startView = await import(`./views/${splitName[0]}/${splitName[1]}/${splitName[2]}.jsx`);

			    // // using glob
			    // const startViewPath = partner.startView;
			    // const comps = import.meta.glob('./views/**/*.jsx');
			    // const match = comps[`./views/${startViewPath}.jsx`];

				const startView = await import(`./views/${partner.startView}.jsx`);
				this.setState({
					isLoading: false,
					workflows: partnerWorkflows,
					currentWorkflow: currentWorkflow,
					signViews: signViews,
					startView: startView.default,
					assentlyComponents: assentlyComponents
				}, () =>
				{
					this._unhideTimeout = setTimeout(() => this.setState({hide: false}), 100);
				});
			}
		}

		componentWillUnmount()
		{
			this._isMounted = false;
			window.removeEventListener('popstate', this.handleBackButton);
			clearTimeout(this._unhideTimeout);

			if (this.intervalTasks != null)
			{
				clearInterval(this.intervalTasks);
			}
		}

		/**
		 * Tasks that run every couple of seconds. Can be both in "Lobby mode" and in "Advice mode", depending on whether
		 * a CASE_ID is set in Settings or not.
		 */
		runIntervalTasks()
		{
			const currentCaseId = Settings.get("CASE_ID");

			if (currentCaseId)
			{
				this.checkCaseLock(currentCaseId);
			}
		}

		async checkCaseLock(caseId)
		{
			const caseLockStatus = await api("/case/lock/info", {caseId: caseId});

			if (caseLockStatus && caseLockStatus.LockedBy !== "self" && caseLockStatus.LockValid === "1")
			{
				this.setState({caseLockedModalVisible: false, caseLockTakenModalVisible: true, caseLockInfo: caseLockStatus});
			}
			else if (this.state.caseLockTakenModalVisible) // Case lock was returned or released
			{
				this.setState({caseLockTakenModalVisible: false, caseLockInfo: caseLockStatus});
			}
		}

		render()
		{
			const {props, state} = this;
			const {actions, reducers, match, history} = props;
			const {
				busy,
				hasFormValidationErrors,
				user,
				advisor,
				customer,
        customerState,
				customers,
				viewFulfilled,
				showValidationWarning,
				navigationButtons,
				instruments,
				exchangeRates,
				availableBanks,
				availableAdvisors,
				signModalVisible,
				signModalBusy,
				signModalData,
				partner,
				faAuth
			} = reducers.app;

			const classes = createClassName("Cob", {
        "start": match.params.view === "1",
        "notransition": match.params.view === "1",
				"hide": state.hide && !state.isLoading,
				"animate-out": state.animatingOut,
			});

			const viewPropsStart = {
				busy,
				hasFormValidationErrors,
				user,
				advisor,
				customer,
				history,
				setAppState: actions.setAppState,
				partner: partner,
				faAuth
			};

			const viewProps = {
				busy,
				hasFormValidationErrors,
				user: advisor,
				customer,
				history,
				setAppState: actions.setAppState,
				partner: partner,
				faAuth,
				viewNo: match.params.view
			};

			if (state.isLoading) {
				return (
					<div className={classes}>
						<ActivityIndicator color={partner.theme.colors.primary.main} fixed busy/>
					</div>
				)
			}

			const selectedWorkflow = this.state.workflows[state.currentWorkflow];
      const viewId = selectedWorkflow.views.find(item => item.viewIndex === viewProps.viewNo)?.id || null;

			return (
				<div className={classes}>
					<Form id="cobform"
						  ref={this.formRef}
						  onSubmit={this._onFormSubmitAsync}
						  autoComplete={false}
						  submitOnEnter={false}
						  defaultFieldValue=""
              >

						<DefaultFields fields={this.defaultFieldValues}/>

						{match.params.view !== "1" && (
							<ProgressIndicator
								hasFormValidationErrors={hasFormValidationErrors}
								history={history}
								cobForm={this.formRef}
								partner={partner.partnerId}
								customer={customer}
								viewNumber={match.params.view}
								availableBanks={availableBanks}
								views={selectedWorkflow.views}
								currentWorkflow={state.currentWorkflow}
                isNotesDrawerOpen={state.notesDrawerOpen}
                toggleNotesDrawer={this._toggleNotesDrawer}
                readOnly={customerState.isReadOnly}
							/>
						)}
            {match.params.view !== "1" && customerState.isReadOnly && (
              <Box
                className="sign-on-screen-notice Notice warning custom-message"
                sx={{
                  m: 1,
                  with: "100%",
                  maxWidth: "unset !important",
                }}
              >
                <Typography variant="body2" color="primary.main">
                  <b>OBS:</b> Ärendet visas i skrivskyddat läge - inga ändringar sparas
                </Typography>
                </Box>
            )}
						{match.params.view === "1" && (
							<state.startView
								{...viewPropsStart}
								navigationButtons={navigationButtons}
                                customers={customers}
								instruments={instruments}
								exchangeRates={exchangeRates}
								assentlyStatus={state.assentlyComponents}
								availableBanks={availableBanks}
								onRestartSession={this._onRestartSession}
								onWorkflowSwitched={this._onWorkflowSwitched}
								onShareClose={this._onShareClose}
								onShareOpen={this._onShareOpen}
								cobForm={this.formRef}
							/>
						)}

						{selectedWorkflow.views.map(({viewIndex, View: CobView}, i) => (match.params.view !== "1" && parseInt(match.params.view, 10) === (parseInt(viewIndex, 10)) && (
              <>
                <NotesDrawerPortalWrapper 
                  open={state.notesDrawerOpen}
                  user={user}
                  // advisor={advisor}
                  customer={customer}
                  viewId={viewId}
                  onToggle={this._toggleNotesDrawer}         
                />
                <CobView
                  {...viewProps}
                  key={i}
                  navigationButtons={navigationButtons}
                  instruments={instruments}
                  exchangeRates={exchangeRates}
                  assentlyStatus={state.assentlyComponents}
                  availableBanks={availableBanks}
                  onRestartSession={this._onRestartSession}
                  currentWorkflow={state.currentWorkflow}
                  readOnly={customerState.isReadOnly}
                />
              </>
						)))}

						{state.signViews.map(({View: SignView}, i) => (match.params.view === "sign" && (
              <>
                <NotesDrawerPortalWrapper 
                  open={state.notesDrawerOpen}
                  user={user}
                  // advisor={advisor}
                  customer={customer}
                  viewId={`Sign-${partner.partnerId}`}
                  onToggle={this._toggleNotesDrawer}         
                />
							<SignView {...viewPropsStart}
									  key={i}
									  signModalVisible={signModalVisible}
									  signModalBusy={signModalBusy}
									  signModalData={signModalData}
									  onRestartSession={this._onRestartSession}
									  setAppState={actions.setAppState}
                    readOnly={customerState.isReadOnly}
                    />
              </>
						)))}

						{match.params.view === "finish" && (
							<CobFinish {...viewProps} />
						)}

						<Footer app={reducers.app}
						        hidden={(match.params.view <= 1)}
						        partner={partner}
						        user={user}
						        customer={customer}
						        advisors={availableAdvisors}
						        busy={busy}
						        view={
							        match.params.view ?
								        match.params.view === "sign" ?
									        match.params.view
									        : parseInt(match.params.view, 10)
								        : null
						        }
                    cobForm={this.formRef}
						        setAppState={actions.setAppState}
						        viewFulfilled={viewFulfilled}
						        showValidationWarning={showValidationWarning}
                    readOnly={customerState.isReadOnly}
						        showShareDialog={state.showShareDialog}
						        shareDialogLoading={state.shareDialogLoading}
						        sharingCaseId={state.sharingCaseId}
						        sharingData={state.sharingData}
						        shareError={state.shareError}
						        navigationButtons={navigationButtons}
						        onRestartSession={this._onRestartSession}
						        onQuitSession={this._showQuitSessionModal}
						        onNavigationBack={this._onNavigationBack}
						        onShareClose={this._onShareClose}
						        onShareOpen={this._onShareOpen}
                    toggleNotesDrawer={this._toggleNotesDrawer}
						/>
					</Form>

					<Modal visible={state.quitSessionModalVisible}
					       view={
						       match.params.view ?
							       match.params.view === "sign" ?
								       match.params.view
								       : parseInt(match.params.view, 10)
							       : null
					       }
					       title={i18n("cob", "quit_session")}
					       status="danger">

						<p>Är du säker på att du vill avsluta sessionen?</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "no")} appearance="primary"
							              onClick={this._hideQuitSessionModal}/>
							<Modal.Button label={i18n("general", "yes") + ", " + i18n("cob", "quit_session", true)}
							              appearance="primary" onClick={this._onQuitSession} filled/>
						</Modal.ButtonsContainer>
					</Modal>

					<Modal visible={state.openedDraftModalVisible}
					       title={i18n("footer", "end_consultation")}
					       status="danger">
						<p>Ärendet har redan skickats.</p>
						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("footer", "end_consultation")}
							              appearance="primary" onClick={this._onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</Modal>

					<Modal visible={state.caseLockedModalVisible} title={i18n("cob", "case_locked_header")} status="danger">
						<p>{i18n("cob", "case_locked_text")}</p>
						{state.caseLockInfo && (
							<p style={{marginBottom: "0"}}>
								<strong>{i18n("cob", "case_locked_held_by")}:</strong> {state.caseLockInfo.LockedByName}<br />
								<strong>{i18n("cob", "case_locked_released")}:</strong> {state.caseLockInfo.LockedUntil}
							</p>
						)}

						<StackLayout className="Modal-buttons-container" justifyContent="space-between" fullWidth>
							<Modal.Button label={i18n("cob", "case_locked_wait")} appearance="primary"
										  onClick={this._hideCaseLockedModal}/>
							<Modal.Button label={i18n("cob", "case_locked_force")} appearance="primary"
										  onClick={this._onTakeLock.bind(this, state.caseLockInfo ? state.caseLockInfo.CaseId : null)} filled/>
						</StackLayout>

						<p style={{fontSize: "12px", color: "#7d7d7d", marginTop: "10px"}}>
							{i18n("cob", "case_locked_explanation")}
						</p>
					</Modal>

					<Modal visible={state.caseLockTakenModalVisible} title={i18n("cob", "case_locked_header")} status="danger">
						{!state.caseLockInfo && (
							<p>{i18n("cob", "case_locked_taken_by_other")}</p>
						)}
						{state.caseLockInfo && (
							<p>
								{i18n("cob", "case_locked_taken_by_advisor")} <strong>{state.caseLockInfo.LockedByName}</strong>.
							</p>
						)}

						<p>{i18n("cob", "case_locked_session_will_end")}</p>
						<StackLayout className="Modal-buttons-container" justifyContent="end" fullWidth>
							<Modal.Button label={i18n("footer", "end_consultation")} appearance="primary"
										  onClick={this._onRestartSession} filled/>
						</StackLayout>
					</Modal>
				</div>
			);
		}

		// Internal methods
		_showDraftOpenedModal = () => this.setState({openedDraftModalVisible: true});
		_showQuitSessionModal = () => this.setState({quitSessionModalVisible: true});
		_hideQuitSessionModal = () => this.setState({quitSessionModalVisible: false});
		_hideCaseLockedModal = () => this.setState({caseLockedModalVisible: false, caseLockTakenModalVisible: false, caseLockInfo: null}, () => {
			this.props.actions.setAppState({busy: false});
		});
    _toggleNotesDrawer = () => this.setState({notesDrawerOpen: !this.state.notesDrawerOpen});

		_onTakeLock = async (caseId) => {
			if (!caseId)
			{
				return;
			}
			await api("/case/lock/force", {caseId: caseId});

			// Re-submit the form, it should be OK this time since we've force taken over the lock. The other
			// advisor will have been kicked out.
			this.formRef.current._onSubmit();
		}

		_onWorkflowSwitched = (newWorkflow, callback) => {
			this.setState({currentWorkflow: newWorkflow}, () =>
			{
				callback();
			});
		}

		_onQuitSession = () =>
		{
			Settings.clear();
			window.location.href = "/";
		};

		_onRestartSession = () =>
		{
			const {reducers, history, actions} = this.props;
			const {customer} = reducers.app;

			this.setState({caseLockTakenModalVisible: false, openedDraftModalVisible: false}, () =>
			{
				actions.setAppState({
					busy: false,
					signModalVisible: false,
					customerState: {
						isCompany: false,
						ssnOrg: "",
					},
          hasFormValidationErrors: false
				});

				customer.resetCustomer();
				Settings.remove("CASE_ID");
				this.latestCustomerDocuments = customer ? customer.documents : [];
				_requestCleanAuthToken.call(this);

				history.push("/cob/1");
				Settings.set("LAST_VIEW", "1");
			});
		};

		handleBackButton = (event) =>
		{
			event.preventDefault();
			const {match, reducers} = this.props;
			const {customer} = reducers.app;
			const view = parseInt(match.params.view || "1", 10);

			// Handle the back button press here
			Settings.set("LAST_VIEW", view);
			if (view === 1)
			{
				customer.resetCustomer();
				Settings.remove("CASE_ID");
				this.latestCustomerDocuments = customer ? customer.documents : [];
				_requestCleanAuthToken.call(this);
			}

			return false;
		};

		_onShareClose = () =>
		{
			this.setState({showShareDialog: false, sharingData: [], shareError: false, shareDialogLoading: true, sharingCaseId: null});
		};

		_onShareOpen = async (caseId) =>
		{
			const {reducers} = this.props;
			const {customer, advisor} = reducers.app;
			let advisorSsn = customer._advisorSsn;

			if (advisorSsn === null && advisor !== undefined && advisor !== null)
			{
				advisorSsn = advisor.ssn;
			}

			this.setState({showShareDialog: true, sharingData: [], shareError: false, shareDialogLoading: true}, async () => {
				try
				{
					const sharing = await api("/case/sharing", {
						caseId,
						userId: advisorSsn,
						partnerId: advisorSsn
					});
					this.setState({shareDialogLoading: false, sharingData: sharing, sharingCaseId: caseId, shareError: false});
				}
				catch (ex)
				{
					this.setState({shareDialogLoading: false, sharingData: [], sharingCaseId: caseId, shareError: true});
				}
			});
		};

		// _onNavigationBack = () =>
		// {
		// 	const {history, match, reducers} = this.props;
		// 	const {customer} = reducers.app;
		// 	const isSignPage = match.params.view === "sign";
		// 	const view = parseInt(match.params.view || "1", 10);

		// 	if (reducers.app.navigationButtons.back.onClick)
		// 	{
		// 		reducers.app.navigationButtons.back.onClick();
		// 	}

		// 	if (isSignPage)
		// 	{
		// 		let prevView = Settings.get("LAST_VIEW");

		// 		if (prevView === "sign")
		// 		{
		// 			prevView = this.state.workflows[this.state.currentWorkflow].views.length + 1;
		// 		}
		// 		history.push("/cob/" + prevView);
		// 	}
		// 	else if (view > 1)
		// 	{
		// 		/**
		// 		 * Check if the previous view is enabled for this type of customer (company | private individual).
		// 		 * Why -3? Current view - 1 is the previous view. Current view - 2 is because we add a view at the
		// 		 * start (start page is view 1). Current view - 3 is because the array is zero based.
		// 		 */
		// 		let prevView = (view - 3);
		// 		for (let i = prevView; i > 0; i--)
		// 		{
		// 			const view = this.state.workflows[this.state.currentWorkflow].views[i];
		// 			if ((view.isDisabledForCompanies && customer.isCompany) ||
		// 				(view.isDisabledForPersons && !customer.isCompany))
		// 			{
		// 				console.log("Navigating back :: Skipping view " + view.id + " :: not enabled for this customer type");
		// 				continue;
		// 			}
		// 			prevView = parseInt(view.viewIndex, 10);
		// 			break;
		// 		}

		// 		// If the last view was not enabled we go to the sign page
		// 		if (prevView <= 1)
		// 		{
		// 			prevView = 2;
		// 		}

		// 		history.push("/cob/" + prevView);
		// 		Settings.set("LAST_VIEW", prevView);
		// 	}
		// };

		_onFormSubmitAsync = async (formData, additionalData) =>
		{
        const { triggeredFrom, viewIndex } = additionalData;

        const isCobStepsClick = (triggeredFrom === 'CobStepsButton');
        const isFormPreviousClick = (triggeredFrom === 'CobFooterPreviousButton');
        const isFormContinueClick = (triggeredFrom === 'CobFooterContinueButton' || triggeredFrom === 'InitSessionContinueBtn');
        const isIgnoreWarningClick = (triggeredFrom === 'CobFooterIngnoreWarningButton');
  
        const {actions, reducers, history, match} = this.props;
        const {partner, advisor, user, customer, customerState, viewFulfilled} = reducers.app;
        const currentView = parseInt(match.params.view || "1", 10);
        const currentViewIsSign = match.params.view === "sign";
        const standaloneApplicationChecked = (customerState.standaloneApplicationsChecked?.length > 0);
        const { isReadOnly } = customerState;
        

        if (!isReadOnly && !this.intervalTasks) {
          this.intervalTasks = setInterval(() => this.runIntervalTasks(), 3000);
          this.runIntervalTasks();
        }
        else if (isReadOnly && this.intervalTasks) {
          clearInterval(this.intervalTasks);
          this.intervalTasks = null;
        }
  
        // If standalone application is checked, redirect to the standalone application
        if (standaloneApplicationChecked) {
  
          let standaloneApp = null;
          for (let i = 0; i < partner.standaloneApplications.length; i++)
          {
            if (partner.standaloneApplications[i].label === customerState.standaloneApplicationsChecked[0])
            {
              standaloneApp = partner.standaloneApplications[i];
              break;
            }
          }
  
          if (standaloneApp === null)
          {
            console.error("Invalid app configuration", customerState.standaloneApplicationsChecked);
            return;
          }
  
          // Create a checksum that the cob odin client will check to make sure that the request
          // originated in a correct way.
          const encodedContext = createB64Context("fairclient", user, formatSSN(customerState.ssnOrg, {withDash: false}), null);
          return window.location.href = standaloneApp.url + stringifyQueryParams({
            context: encodedContext,
            token: Settings.get("AUTH_TOKEN"),
            key: standaloneApp.key
          });
        }
  
        // If cob steps click on current view do nothing
        if (isCobStepsClick && viewIndex === match.params.view)
        {
          return;
        }
  
        // If not cob steps click and view is not fulfilled show validation errors and warning prompt
        if (isFormContinueClick && !viewFulfilled && !currentViewIsSign && !isReadOnly)
        {
          return actions.setAppState({showValidationWarning: true});
        }
  
        // clear validation warning
        if (isIgnoreWarningClick)
        {
          actions.setAppState({showValidationWarning: false});
        }
        
        const {caseId} = formData;
        // If we're loading a case for editing, we first check the status of the Case Lock to make sure that
        // nobody else is editing the case at the same time. The lock will be created for the current user
        // if it doesn't already exist.
        if (!isReadOnly && currentView === 1 && caseId)
        {
          actions.setAppState({busy: true});
          const caseLockStatus = await api("/case/lock", {caseId: caseId});
          if (caseLockStatus.LockedBy !== "self")
          {
            this.setState({caseLockedModalVisible: true, caseLockTakenModalVisible: false, caseLockInfo: caseLockStatus});
            return;
          }
          this.setState({caseLockedModalVisible: false, caseLockTakenModalVisible: false, caseLockInfo: caseLockStatus});
        }
  
        const hasNewDocuments = this.latestCustomerDocuments !== customer.documents && (this.latestCustomerDocuments.length > 0 || customer.documents.length > 0);
  
        const goToSelectedView = async view =>
        {
          history.push("/cob/" + view);
          Settings.set("LAST_VIEW", view);
        }
  
        const goToNextView = async () =>
        {
          /**
           * After the last view has been completed, we go to the sign page
           */
          let nextViewIsSign = ((currentView - 1) >= this.state.workflows[this.state.currentWorkflow].views.length) && customer.getData("radio_advice_insurance") !== "yes";
          let nextView = -1;
          if (!nextViewIsSign)
          {
            /**
             * Navigate to the next view that is available and enabled. Some views are marked as not available for
             * companies and some views are marked as not available for private individuals, these should be skipped.
             */
            for (let i = (currentView - 1); i < this.state.workflows[this.state.currentWorkflow].views.length; i++)
            {
              const view = this.state.workflows[this.state.currentWorkflow].views[i];
              if ((view.isDisabledForCompanies && customer.isCompany) || (view.isDisabledForPersons && !customer.isCompany))
              {
                console.log("Navigating forward :: Skipping view " + view.id + " :: not enabled for this customer type", view.viewIndex);
                continue;
              }
              nextView = parseInt(view.viewIndex, 10);
              break;
            }
  
            // If the last view was not enabled we go to the sign page
            if (nextView === -1)
            {
              nextViewIsSign = true;
            }
          }
  
          if (nextViewIsSign)
          {
            history.push("/cob/sign");
          }
          else if (currentViewIsSign)
          {
            // We are on the signature page and pressed the next button, special case for signing
            const {success} = await _signAgreementAsync.call(this);
            if (success)
            {
              history.push("/cob/finish");
            }
          }
          else
          {
            history.push("/cob/" + nextView);
            Settings.set("LAST_VIEW", nextView);
          }
  
          window.scrollTo(0, 0);
        };
  
        actions.setAppState({busy: true}); // Set app to busy state
  
        /**
         * Fake delay to force showing of activity indicator on some special pages of the wizard
         */
        if (!isReadOnly && !isCobStepsClick && (hasNewDocuments || currentViewIsSign || currentView === 1))
        {
          await delay(50);
        }
  
        const newAppStateProps = {
          busy: false
        };
  
        let shouldResetWorkflow = false;
        let newWorkflowStateProp = null;
  
        if (!isReadOnly && hasNewDocuments && customer.caseId)
        {
          try
          {
            let nDocUploaded = 0;
  
            customer.updateFormData(formData);
            await customer.uploadDocuments();
  
            // mark docs as uploaded and count them if applicable, exclude docs already uploaded (uploaded === true) or already on DB (which have 'document_name' property instead)
            customer.documents.forEach(doc =>
            {
              if (doc.name && !doc.uploaded)
              {
                doc.uploaded = true;
                nDocUploaded = nDocUploaded + 1;
              }
            });
            console.log(`Uploaded ${nDocUploaded} document(s).`);
  
            this.latestCustomerDocuments = customer.documents; // To make sure upload is not retried later
          }
          catch (e)
          {
            /**
             * We got a file upload error. If we are on the file upload page we show an error message, otherwise
             * ignore it since we don't want users to get stuck on views without the file upload box.
             */
            const sections = this.state.workflows[this.state.currentWorkflow].views;
            let fileUploadViewIndex = -1;
            for (let i = 0; i < sections.length; i++)
            {
              if (sections[i].id === "UploadDocuments" || sections[i].id === "UploadDocuments-Fair")
              {
                fileUploadViewIndex = sections[i].viewIndex + "";
                break;
              }
            }
  
            if ('type' in e && e.type === 'FILE' && (currentView + "") === fileUploadViewIndex)
            {
              console.log("Upload failed, view " + currentView);
              actions.setAppState({busy: false, errorModalVisible: true, errorModalMessage: e.message});
              return;
            }
          }
        }
  
        if (!isReadOnly && currentViewIsSign)
        {
          try
          {
            await _updateCase.call(this, "sign", formData, this.state.currentWorkflow);
  
            // Update customer data
            customer.updateFormData(formData);
          }
          catch (e)
          {
            if (e.message === "REQUEST_TIMED_OUT")
            {
              actions.setAppState({busy: false, errorModalVisible: true, errorModalMessage: e.message});
              return;
            }
          }
  
          let hasFormValidationErrors = _checkFormValidationErrors.call(this);
  
          if (hasFormValidationErrors && !isCobStepsClick && !isFormPreviousClick)
          {
            actions.setAppState({
              busy: false,
              hasFormValidationErrors,
            });
          }
  
          if (!isFormPreviousClick && !isCobStepsClick && !hasFormValidationErrors)
          {
            // Open sign modal
            const signModalData = {
              signatories: customer.getSignatoriesData().map(s => ({...s, selected: true})),
              signOnScreen: false
            };
            actions.setAppState({busy: false, signModalVisible: true, signModalData});
            return;
          }
        }
        else if (currentView === 1)
        {
          const {caseId} = formData;
          delete formData.caseId;
  
          try
          {
            if (!caseId)
            {
              throw new Error("NO_CASE_ID");
            }
  
            let partnerUser = advisor;
            if (!partnerUser && this.tempAdvisor)
            {
              partnerUser = this.tempAdvisor;
              console.log("Using partner user from object");
            }
  
            let actualUser = user;
            if (!actualUser && this.tempUser)
            {
              actualUser = this.tempUser;
              console.log("Using actual user from object");
            }

            const {
              isDraft,
              isRecalled,
              workflow
            } = await customer.loadDataFromCase(caseId, actualUser.ssn, partnerUser.ssn);
            newWorkflowStateProp = workflow;
            shouldResetWorkflow = true;
  
            /**
             * When initially reading the case, check if this was a draft case or if it has been sent for signing already.
             * Also check whether the Assently template has been changed since we last edited the case.
             *
             * If the case has been sent for signing we ALWAYS create a new case based on the data in the old, signed case
             * and we NEVER delete the signed version.
             */
            if (!isReadOnly && isDraft && !isRecalled)
            {
              customer._originalCaseId = null;
  
              // Save case id to settings
              Settings.set("CASE_ID", caseId);
              console.log("Draft case. Fetched and saved latest case ID:", caseId);
            }
            else if (isReadOnly)
            {
              customer._originalCaseId = caseId;
  
              // Save case id to settings
              Settings.set("CASE_ID", caseId);
              console.log("Read only mode requested. Fetched and saved latest case ID:", caseId);
            }
            else
            {
              console.log("Signed or recalled case. Will create new case with latest data.");
              customer._originalCaseId = caseId;
              customer.clearCaseDataAfterRestoringFromSigned();
              this.latestCustomerDocuments = [];
  
              Settings.remove("CASE_ID"); // Will remove any existing case id from settings
              await _requestCleanAuthToken.call(this); // Request clean (new) auth token not bound to any case
            }
          }
          catch (e)
          {
            console.log("This is a new customer. Will create new case.", e);
            customer._originalCaseId = null;
            Settings.remove("CASE_ID"); // Will remove any existing case id from settings
          }
  
          customer.updateFormData(formData);
        }
        else if (!isReadOnly && currentView > 1)
        {
          try
          {
            const currentCaseId = Settings.get("CASE_ID");
            if (currentCaseId && currentCaseId !== customer.caseId)
            {
              actions.setAppState({
                busy: false,
                errorModalVisible: true,
                errorModalForceRestart: true,
                errorModalMessage: "Vänligen starta om rådgivningstillfället."
              });
              return;
            }
  
            await _updateCase.call(this, currentView, formData, this.state.currentWorkflow);
  
            // Update customer data
            customer.updateFormData(formData);
          }
          catch (e)
          {
            if (e.message === "REQUEST_TIMED_OUT")
            {
              actions.setAppState({
                busy: false,
                errorModalVisible: true,
                errorModalForceRestart: false,
                errorModalMessage: e.message
              });
              return;
            }
  
            actions.setAppState({
              busy: false,
              errorModalVisible: true,
              errorModalForceRestart: true,
              errorModalMessage: "Vänligen starta om rådgivningstillfället genom att klicka på knappen nedan. Om felet kvarstår, var god kontakta backoffice support."
            });
            return;
          }
        }
  
        // Make sure correct workflow is selected and used for the case
        if (shouldResetWorkflow)
        {
          newAppStateProps.hasFormValidationErrors = false;
          this.setState({currentWorkflow: newWorkflowStateProp == null ? "default" : newWorkflowStateProp});
        }
  
        if (!isReadOnly && reducers.app.hasFormValidationErrors)
        {
          // if hasFormValidationErrors has been triggered check again if it can be set false
          let hasFormValidationErrors = _checkFormValidationErrors.call(this);
          if (!hasFormValidationErrors)
          {
            actions.setAppState({
              busy: false,
              hasFormValidationErrors,
            });
          }
        }
  
        if (Object.keys(newAppStateProps).length > 0)
        {
          actions.setAppState(newAppStateProps);
        }
  
        if (isCobStepsClick)
        {
          return goToSelectedView(viewIndex);
        }

        if (isFormPreviousClick)
        {
          if (viewIndex === 'sign')
          {
            // Get the last viewIndex from the current workflow
            const selectedWorkflow = this.state.workflows[this.state.currentWorkflow];
            const lastViewIndex = selectedWorkflow.views[selectedWorkflow.views.length - 1].viewIndex;
            return goToSelectedView(lastViewIndex);
          }
          return goToSelectedView(viewIndex - 1);
        }
  
        goToNextView();
		};
	}


// PRIVATE FUNCTIONS
function _checkFormValidationErrors()
{
	const {customer} = this.props.reducers.app;
    const selectedWorkflow = this.state.workflows[this.state.currentWorkflow];
	const views = selectedWorkflow.views;
	const signViews = this.state.signViews;

	let hasErrors = false;

	views.forEach(view =>
	{
		const viewState = view.getInitialState(customer, this.state.currentWorkflow);
    	viewState.isCompany = customer.isCompany;
		const isFulfilled = view.isViewFulfilled(viewState);

		if (!isFulfilled)
		{
			hasErrors = true;
		}
	});

	signViews.forEach(view =>
	{
		const viewState = view.getInitialState(customer, this.state.currentWorkflow);
		const isFulfilled = view.isViewFulfilled(viewState);
		if (!isFulfilled)
		{
			hasErrors = true;
		}
	});

	return hasErrors;
}

function _setupExchangeRatesAsync()
{
	return new Promise(async (resolve, reject) =>
	{
		if (this.props.exchangeRates)
		{
			resolve(this.props.exchangeRates);
			return;
		}

		try
		{
			console.log("Fetching Exchange Rates...");
			const exchangeRates = await api("/exchange/rates");
			resolve(exchangeRates);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

function _setupAvailableAdvisorsAsync()
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Fetching Available Advisors...");
			const result = await api("/advisors");
			resolve(result);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

function _setupAvailableBanksAsync()
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Fetching Available Banks...");
			const result = await api("/banks");
			resolve(result);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

async function _setupCustomer()
{
	const customer = new Customer();
	const currentCaseId = Settings.get("CASE_ID");
  let customerState = {};
	let caseNotFound = false;
	let caseWorkflow = "default";

	if (currentCaseId)
	{
		try
		{
			const {reducers, actions} = this.props;
			const {user, advisor} = reducers.app;

			let partnerUser = advisor;
			if (!partnerUser && this.tempAdvisor)
			{
				partnerUser = this.tempAdvisor;
				console.log("Using partner user from object");
			}

			let actualUser = user;
			if (!actualUser && this.tempUser)
			{
				actualUser = this.tempUser;
				console.log("Using actual user from object");
			}

			const {workflow, isDraft, isSent, isSigned} = await customer.loadDataFromCase(currentCaseId, actualUser.ssn, partnerUser.ssn);
			console.log(`Loaded case with id: ${currentCaseId} (${workflow}) :: Draft ${isDraft}`);
			caseWorkflow = workflow;

      const isAdminOrBackoffice = (actualUser.role === "admin" || actualUser.role === "backoffice");

			if (!isDraft && !(isAdminOrBackoffice || isSent || isSigned))
			{
				this._showDraftOpenedModal();
			}

      if (isAdminOrBackoffice || isSent || isSigned)
      {
        customerState = {
          isReadOnly: true
        }
      }
		}
		catch (e)
		{
			caseNotFound = true;
			console.error(`Case not found with ID: ${currentCaseId}. Will create new case.`);
			Settings.clear();
			window.location.href = "/";
		}
	}

	return {customer, customerState, caseNotFound, caseWorkflow};
}

function _signAgreementAsync()
{
	return new Promise(async resolve =>
	{
		resolve({success: false});
	});
}

async function _updateCase(view, formData, workflow)
{
	const {reducers, history} = this.props;
	const {user, advisor, customer} = reducers.app;
	const currentCaseId = Settings.get("CASE_ID");

	try
	{
		const apiMethod = !currentCaseId ? "new" : "update";

		/**
		 * If we're updating with empty form fields, skip to avoid an error (no point in updating anyway since data
		 * won't be changed).
		 */
		if (apiMethod === "update" && isEmpty(formData))
		{
			return;
		}

		if (view === 2)
		{
			console.log("Sending all form fields initially");
			formData = {...customer._formData, ...formData}; // merge with the formData that we just inputted on screen
		}

		const {caseId, client} = await api(`/case/${apiMethod}`, {
			userId: !currentCaseId ? user.ssn : undefined,
			partnerId: !currentCaseId ? advisor.ssn : undefined,
			customerId: !currentCaseId ? formatSSN(customer.ssnOrg, {withDash: false}) : undefined,
			caseId: currentCaseId || undefined,
			originalCaseId: customer._originalCaseId || null,
			formFields: formData,
			workflow: workflow
		});

		// We only get the caseId for a new case after view 2 has been submitted so let's set view 2 to seen in localStorage with the corresponding case id
		if (view === 2)
		{
			const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(caseId)}`)) || [];
			const hasBeenSeen = (seenViews.includes(view.toString()));
			// if it hasn't been seen add the view to local storage for the specific case
			if (caseId && !hasBeenSeen)
			{
				localStorage.setItem(`SEEN_VIEWS_${btoa(caseId)}`, JSON.stringify([...seenViews, view.toString()]));
			}
		}

		if (!currentCaseId)
		{
			Settings.set("CASE_ID", caseId);
			console.log("Created new case with ID:", caseId);
			customer._caseId = caseId;
			customer._clientData = client;

			console.log("Creating case lock", caseId);
			await api("/case/lock", {caseId: caseId});
		}
		else
		{
			console.log("Updating case lock", currentCaseId);
			await api("/case/lock", {caseId: currentCaseId});
		}

		console.log("Updated case with fields from view:", view);
	}
	catch (e)
	{
		console.error(e.message);

		if (e.message === "AUTH_TOKEN_UNDEFINED" || e.message === "AUTH_TOKEN_REJECTED" || e.message === "AUTH_TOKEN_INVALID")
		{
			Settings.clear();
			history.push("/");
			window.location = "/";
			return;
		}

		throw e;
	}
}

async function _requestCleanAuthToken()
{
	const {customer} = this.props.reducers.app;
	const {authToken, newCaseId} = await api("auth/clean-token");

	Settings.set("AUTH_TOKEN", authToken);
	customer._caseId = newCaseId;

	return authToken;
}

/**
 * Values that should always be set.
 */
function _getDefaultFieldValues()
{
	return [
		{name: "checkbox_customer_kyc_non_proff", value: "yes"}
	];
}

// Create a wrapper outside the main React component hierarchy using React Portals
const NotesDrawerPortalWrapper = (props) => {
  return ReactDOM.createPortal(
    <NotesDrawer 
      open={props.open}
      user={props.user}
      // advisor={advisor}
      customer={props.customer}
      viewId={props.viewId}
      onToggle={props.onToggle}
    />,
    // document.body // Appends to the body, making it relative to the whole page
    document.getElementById('leApp')
  );
};

export default connectRedux(state => ({app: state.app}), {setAppState: appActions.setState}, CobRoute);