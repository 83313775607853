import React from "react";

// HELPERS
import { createClassName, createUid } from "@helpers/utils";


export default class Checkbox extends React.PureComponent {
    uid = createUid()
    state = {
        focused: false,
        invalid: false
    }

    render() {
        const { props, state } = this;
        const { style, type, checked, label, title, name, value, required, disabled, tooltip, rounded, alignTop, labelAlign, onChange, highlightErrors, groupValid, readOnly} = props;
        const classes = createClassName(props.className, {
            "Checkbox": true,
            "checked": checked,
            "focus": state.focused,
            "tooltip": !!tooltip,
            "required": required === true,
            "disabled": disabled === true,
            "read-only": readOnly === true,
            "radio": type === "radio",
            "round": rounded === true,
            "align-top": alignTop === true,
            "label-left": labelAlign === "left",
            "invalid": highlightErrors && !checked && !groupValid,
        });

        return (
            <div className={ classes } style={ style } title={ title }>
                <label htmlFor={ this.uid } onClick={ this._onClick }>
                    { !!label && labelAlign === "left" && <div className="Checkbox-label">{ label }</div> }
                    <div className="Checkbox-checkbox">
                        <div className="icon material-icons">done</div>
                    </div>
                    { !!label && labelAlign !== "left" && <div className="Checkbox-label">{ label }</div> }
                </label>
                <input
                    ref={ ref => this.input = ref }
                    className="Checkbox-input"
                    type={ type === "radio" ? "radio" : "checkbox" }
                    id={ this.uid }
                    name={ name }
                    value={ value }
                    checked={ checked }
                    required={ required }
                    readOnly={ readOnly }
                    disabled={ disabled }
                    onFocus={!disabled && !readOnly ? this._onFocus : undefined}
                    onBlur={ !disabled && !readOnly ? this._onBlur : undefined }
                    onChange={ onChange && !disabled && !readOnly ? this._onChange : undefined }
                />
                { !!tooltip && <CheckboxTooltip text={ tooltip } /> }
                {checked === false && highlightErrors && !groupValid &&
                  <div className="Checkbox-status material-icons">error</div>
                }
            </div>
        );
    }

    // Internal methods
    _onClick = (e) => e.stopPropagation();

    _onFocus = (e) => {
        const { onFocus } = this.props;
        this.setState({ focused: true }, onFocus ? onFocus(e) : null);
    }

    _onBlur = (e) => {
        const { onBlur } = this.props;
        this.setState({ focused: false }, onBlur ? onBlur(e) : null);
    }

    _onChange = (e) => {
        const { onChange } = this.props;
        const { checked, value } = this.input;
        onChange(checked, value);
    }
}


// PRIVATE COMPONENTS
function CheckboxTooltip({ text, onClick }) {
    return (
        <div className="Checkbox-tooltip">
            <div className="Checkbox-tooltip-icon">?</div>
            <div className="Checkbox-tooltip-text">{ text }</div>
        </div>
    );
}