import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import TextView from "@components/TextView/TextView";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// OTHER
import fulfillment from "./MeetingNotes.fulfillment";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import TextField from "../../../../../components/TextField/TextField";
import Settings from "../../../../../classes/Settings";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator.jsx";


export default class MeetingNotesView extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	async componentDidMount()
	{
		const {setAppState} = this.props;

		this.setCustomerState({
			knowledgeScore: "-",
			knowledgeScoreLoading: true
		}, () => {
			// Set navigation events
			setAppState({
				viewFulfilled: this.isFulfilled(),
				showValidationWarning: false,
				navigationButtons: {
					sharing: {},
					back: {},
					next: {
						onClick: () =>
						{
							if (this.state.isFulfilled === true)
							{
								return;
							}

							this.setState({hasFormValidationErrors: true});
							return setAppState({
								showValidationWarning: true
							});
						}
					}
				}
			});
		});

		const knowledgeScore = await _getCustomerKnowledgeScore.call(this);
		this.setCustomerState({
			knowledgeScore: knowledgeScore,
			knowledgeScoreLoading: false
		});
	}

	render()
	{
		const {state} = this;
		const {customerState, hasFormValidationErrors, readOnly} = state;
		const classes = createClassName("Cob-view Cob-view8", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Lämplighetsbedömning, slutsats</h2>

					<div className="Cob-view8-fields">

						{customerState.knowledgeScoreLoading && (
							<div style={{
								marginTop: "20px",
								padding: "20px",
								border: "1px solid #CCC",
								width: "100%",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								background: "#fff8e5",
								fontSize: "15px",
								fontWeight: "300",
								color: "#000"
							}}>
								<ActivityIndicator color="#4cb7d5" scale={0.5} busy />
								<span style={{marginLeft: "10px"}}>Beräknar kunskapsnivå...</span>
							</div>
						)}

						{!customerState.knowledgeScoreLoading && (
							<div style={{
								marginTop: "20px",
								padding: "20px",
								border: "1px solid #CCC",
								width: "100%",
								display: "block",
								textAlign: "center",
								background: "#fff8e5",
								fontSize: "15px",
								fontWeight: "300",
								color: "#000"
							}} dangerouslySetInnerHTML={{__html: customerState.knowledgeScore}}/>
						)}

						<input type="hidden" name="customer_knowledge_level"
							   value={customerState.knowledgeScore.replace("<strong>", "").replace("</strong>", "")}/>

						<h4>Rådgivarens samlade lämplighetsbedömning</h4>

						<Checkbox
							type="checkbox"
							name="checkbox_advice_suitability_goals"
							label="Åtgärderna uppfyller kundens mål"
							value={customerState.checkbox_advice_suitability_goals === "Ja" ? "Ja" : "Nej"}
							checked={customerState.checkbox_advice_suitability_goals === "Ja"}
							onChange={checked => this.setCustomerState({checkbox_advice_suitability_goals: checked ? "Ja" : "Nej"})}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
							style={{paddingRight: "30px"}}
						/>

						<Checkbox
							type="checkbox"
							name="checkbox_advice_suitability_risk"
							label="Kunden kan finansiellt bära den nya risken till följd av åtgärderna"
							value={customerState.checkbox_advice_suitability_risk === "Ja" ? "Ja" : "Nej"}
							checked={customerState.checkbox_advice_suitability_risk === "Ja"}
							onChange={checked => this.setCustomerState({checkbox_advice_suitability_risk: checked ? "Ja" : "Nej"})}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
							style={{paddingRight: "30px"}}
						/>

						<Checkbox
							type="checkbox"
							name="checkbox_advice_suitability_knowledge"
							label="Kunden har den nödvändiga kunskapen/erfarenheten för att förstå riskerna med åtgärderna"
							value={customerState.checkbox_advice_suitability_knowledge === "Ja" ? "Ja" : "Nej"}
							checked={customerState.checkbox_advice_suitability_knowledge === "Ja"}
							onChange={checked => this.setCustomerState({checkbox_advice_suitability_knowledge: checked ? "Ja" : "Nej"})}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
							style={{paddingRight: "30px"}}
						/>

						<Checkbox
							type="checkbox"
							name="checkbox_advice_suitability_other"
							label="Annan bedömning"
							value={customerState.checkbox_advice_suitability_other === "Ja" ? "Ja" : "Nej"}
							checked={customerState.checkbox_advice_suitability_other === "Ja"}
							onChange={checked => this.setCustomerState({checkbox_advice_suitability_other: checked ? "Ja" : "Nej"})}
              readOnly={readOnly}
						/>

						{customerState.checkbox_advice_suitability_other === "Ja" && (
							<TextField
								name="advice_suitability_knowledge_other"
								label="Annan bedömning"
								value={customerState.advice_suitability_knowledge_other}
								validation={value => value.length > 0}
								onChange={({target}) => this.setCustomerState({advice_suitability_knowledge_other: target.value})}
								highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						)}

						<div className="h-line spacing"/>

						<h4>Slutsats</h4>
						<p>På vilket sätt uppfyller föreslagen lösning kundens mål?</p>
						<TextView
							name="advice_customer_suitability2"
							label={`${i18n("general", "notes")} (${1800 - customerState.advice_customer_suitability2.length} tecken kvar)`}
							value={customerState.advice_customer_suitability2}
							maxLength={1800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_customer_suitability2: target.value})}
							fullWidth
							minHeight={250}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						<input type="hidden" name="checkbox_advice_suitable"
							   value={customerState.advice_customer_suitability2.length > 0 ? "Ja" : "Nej"}/>


						<p>Förklara hur kunden kan bära den nya risken</p>
						<TextView
							name="advice_customer_suitability3"
							label={`${i18n("general", "notes")} (${1800 - customerState.advice_customer_suitability3.length} tecken kvar)`}
							value={customerState.advice_customer_suitability3}
							maxLength={1800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_customer_suitability3: target.value})}
							fullWidth
							minHeight={250}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>


						<p>
							Motivera varför kunden har den nödvändiga kunskapen/erfarenheten för att förstå riskerna med
							åtgärdena (är kunskapsnivån högre vid rådgivningstillfället än vid inhämtande av
							grunduppgifterna? Motivera hur det kommer sig).
						</p>
						<TextView
							name="advice_customer_suitability4"
							label={`${i18n("general", "notes")} (${1800 - customerState.advice_customer_suitability4.length} tecken kvar)`}
							value={customerState.advice_customer_suitability4}
							maxLength={1800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({advice_customer_suitability4: target.value})}
							fullWidth
							minHeight={250}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						<div className="h-line spacing"/>

						<h4>Avrådan</h4>
						<Checkbox
							type="checkbox"
							name="checkbox_not_recommended"
							label="Rådgivaren avråder kunden från att teckna lösningen."
							value={customerState.checkbox_not_recommended === "yes" ? "yes" : "no"}
							checked={customerState.checkbox_not_recommended === "yes"}
							onChange={checked => this.setCustomerState({checkbox_not_recommended: checked ? "yes" : "no"})}
              readOnly={readOnly}
							style={{paddingRight: "30px"}}
						/>

						{customerState.checkbox_not_recommended === "yes" && (
							<React.Fragment>
								<p>Åtgärderna är inte lämpliga och avrådes. Motivering lyder:</p>
								<TextView
									name="advice_customer_suitability6"
									label={`${i18n("general", "notes")} (${1000 - customerState.advice_customer_suitability6.length} tecken kvar)`}
									value={customerState.advice_customer_suitability6}
									maxLength={1000}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({advice_customer_suitability6: target.value})}
                  readOnly={readOnly}
									fullWidth
									minHeight={250}
								/>

								<input type="hidden" name="checkbox_advice_not_suitable"
								       value={customerState.advice_customer_suitability6.length > 0 ? "Ja" : "Nej"}/>

								<p>Kunden väljer att ändå teckna. Varför?</p>
								<TextView
									name="advice_customer_suitability7"
									label={`${i18n("general", "notes")} (${1000 - customerState.advice_customer_suitability7.length} tecken kvar)`}
									value={customerState.advice_customer_suitability7}
									maxLength={1000}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({advice_customer_suitability7: target.value})}
                  readOnly={readOnly}
									fullWidth
									minHeight={250}
								/>

								<input type="hidden" name="checkbox_advice_customer_insists"
								       value={customerState.advice_customer_suitability7.length > 0 ? "Ja" : "Nej"}/>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>

						<h4>Ytterligare information, vid behov</h4>
						<TextView
							name="additional_info"
							label={`${i18n("general", "notes")} (${4000 - customerState.additional_info.length} tecken kvar)`}
							value={customerState.additional_info}
							maxLength={4000}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({additional_info: target.value})}
              readOnly={readOnly}
							fullWidth
							minHeight={250}
						/>

					</div>
				</ContentBox>
			</div>
		);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors, readOnly} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
  newState.readOnly = readOnly;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		customerState: {
			checkbox_advice_suitability_goals: customer.getData("checkbox_advice_suitability_goals"),
			checkbox_advice_suitability_risk: customer.getData("checkbox_advice_suitability_risk"),
			checkbox_advice_suitability_knowledge: customer.getData("checkbox_advice_suitability_knowledge"),
			checkbox_advice_suitability_other: customer.getData("checkbox_advice_suitability_other"),
			advice_suitability_knowledge_other: customer.getData("advice_suitability_knowledge_other"),

			advice_customer_suitability2: customer.getData("advice_customer_suitability2"),
			advice_customer_suitability3: customer.getData("advice_customer_suitability3"),
			advice_customer_suitability4: customer.getData("advice_customer_suitability4"),
			advice_customer_suitability6: customer.getData("advice_customer_suitability6"),
			advice_customer_suitability7: customer.getData("advice_customer_suitability7"),
			checkbox_not_recommended: customer.getData("checkbox_not_recommended"),
			additional_info: customer.getData("additional_info"),

			knowledgeScore: "-",
			knowledgeScoreLoading: false
		},
	};

	newState.isFulfilled = false;
	return newState;
}

function _getFields()
{
	return [
		"advice_suitability_knowledge_other",
		"advice_customer_suitability2",
		"advice_customer_suitability3",
		"advice_customer_suitability4",
		"advice_customer_suitability5",
		"advice_customer_suitability6",
		"advice_customer_suitability7",
		"checkbox_not_recommended",
		"customer_knowledge_level",
		"additional_info"
	];
}

function _getCustomerKnowledgeScore()
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const knowledgeScore = await api("/case/knowledge", {
				caseId: Settings.get("CASE_ID")
			});
			resolve(knowledgeScore.text);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}