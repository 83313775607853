import { useState, useEffect } from "react";
import moment from "moment";

// HELPERS
import api from "@helpers/api";
import countries from "@helpers/countries";
import {dateToYearsMonthsDaysAgo} from "@helpers/utils";

const useCustomerInformationCard = props => {
  const { partner, advisor, client, toggleAddCustomerModal, cobForm, setCustomerState, handleModalClose } = props;
  
  const [loaded, setLoaded] = useState(false);
  const [kycStatus, setKycStatus] = useState(null);
  const [timeSinceKyc, setTimeSinceKyc] = useState(null);
  const [kycAction, setKycAction] = useState(null);

  const { street, city, email, phone_alternative } = client;
  const phoneNumber = client.phone || client.phoneNumber;
  const zipCode = client.postal_code || client.zipCode;
  const countryCode = client.country_code || client.country;
  const country = countryCode ? countries.find(country => country.code === countryCode).label : "-";
  const lastSignedDocument = client.last_signed_document || client.lastSignedDocument;
  const clientId = client.client_id || client.clientId;

  const adviceStatus = {
    latestAdviceDate: null,
    timeSinceLatestAdvice: 0
  }

  if (lastSignedDocument) {
    adviceStatus.latestAdviceDate = moment(new Date(lastSignedDocument)).format("YYYY-MM-DD");    

    adviceStatus.timeSinceLatestAdvice = dateToYearsMonthsDaysAgo(adviceStatus.latestAdviceDate);
  }

  const downloadKyc = async () => {
    try {
      const rawDataInBase64 = await api("/case/downloadReceipt", {
        caseId: kycStatus.caseId // kycStatus.caseId not being retrieved in Test, might be cus of dummy data or might need work
      }, {}, true);

      if (rawDataInBase64 !== "")
      {
        console.log("Downloading case data...");
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        //blob = atob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const link = document.createElement('a');
        link.href = "data:application/pdf;base64," + rawDataInBase64;
        link.download = kycStatus.caseId + ".pdf";
        link.click();
      }
      else
      {
        console.log("Could not download case data");
      }
    } catch (e) {
      console.error(e.message);
    }
  }

  const getKycStatus = async () => {
    try {
      const result = await api("/case/kyc-status", {
        clientId,
        wl: partner._name
      });
      
      setKycStatus(result);
      setTimeSinceKyc(result.kycDate ? dateToYearsMonthsDaysAgo(result.kycDate) : null);
      setLoaded(true);
    }
    catch (e) {
      console.error(e.message);
    }
  }

  const startNewAdvice = () => {
    const newStateProps = {
      isCompany: false,
      ssnOrg: client.id,
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      isNewCase: true,
      standaloneApplicationsChecked: [],
      customerMenuIsOpen: false,
    };

    newStateProps.selectedCaseId = null;
    newStateProps.selectedCaseIndex = -1;

    return setCustomerState(newStateProps, () => {
      cobForm.current._onSubmit(null, {
        triggeredFrom: 'CustomerInformationCard',
        viewIndex: null
      });
      handleModalClose();
    });
  }

  const kycDismiss = async () => {
    try {
      await api("/case/kyc-dismiss", {
        identity: client.id,
        userId: advisor._data.ssn
      });
      setKycStatus({...kycStatus, kycNeedsChecking: false });
    }
    catch (e) {
      console.error(e.message);
    }
  }

  useEffect(() => {
    getKycStatus();
  }, []);
  
  return {
    loaded,
    street,
    city,
    zipCode,
    country,
    email,
    phoneNumber,
    phone_alternative,
    adviceStatus,
    kycStatus,
    timeSinceKyc,
    kycAction,
    setKycAction,
    toggleAddCustomerModal,
    downloadKyc,
    startNewAdvice,
    kycDismiss
  };
};

export default useCustomerInformationCard;