import React from "react";

// HELPERS
import * as utils from "@helpers/utils";
import i18n from "@helpers/i18n";


export default class Select extends React.PureComponent {
	state = {
		focused: false,
		dropdownRect: null,
		options: null,
		inputValue: "",
		highlightedIndex: -1,
		selectedIndex: -1,
		selectedOption: null,
	}

	get value() {
		return this.state.selectedOption;
	}

	componentDidMount() {
		const {props} = this;
		const {options, selectedIndex} = props;
		let newState = {options};

		if (options && selectedIndex >= 0) {
			newState = {...newState, selectedIndex, selectedOption: options[selectedIndex] || null};
		}

		this.setState(newState);
	}

	componentDidUpdate(prevProps) {
		const newState = {};

		if (prevProps.options !== this.props.options) newState.options = _getOptions.call(this);

		if (prevProps.selectedIndex !== this.props.selectedIndex) {
			const options = newState.options || this.props.options;

			if (options) {
				newState.selectedIndex = this.props.selectedIndex;
				newState.selectedOption = options[this.props.selectedIndex] || null;
			}
		}

		this.setState(newState);
	}

	render() {
		const {props, state} = this;
		const {style, label, placeholder, inputPlaceholder, name, required, readOnly, highlightErrors} = props;
		const {focused, inputValue, highlightedIndex, selectedIndex, selectedOption} = state;
		const options = state.options || props.options;
		const disabled = props.disabled || !options || props.options.length === 0;
		const classes = utils.createClassName(props.className, {
			"Select": true,
			"focus": focused,
			"placeholder": !!placeholder || focused,
			"highlighting": highlightedIndex >= 0,
			"selected": selectedOption !== null,
			"required": required === true,
			"read-only": readOnly === true,
			"disabled": disabled,
      "invalid": highlightErrors && selectedOption === null,
		});
		const renderSelectedOption = !!selectedOption && !inputValue ? (selectedOption.renderSelectedOption || props.renderSelectedOption) : null;

		return (
			<div ref={ref => this.containerElement = ref} className={classes} style={style}>
				<div ref={ref => this.infoElement = ref} className="Select-info"
					 onClick={!disabled && !readOnly ? this._onInfoClick : undefined}>
					{!!label && <div className="Select-label">{label}</div>}
					{!!selectedOption && !inputValue && (
						<div className="Select-selected">
							{renderSelectedOption ? renderSelectedOption(selectedOption, selectedIndex) : (selectedOption.label || selectedOption.value)}
						</div>
					)}
					{!!placeholder && !selectedOption && <div className="Select-placeholder">{placeholder}</div>}
				</div>
				<input
					ref={ref => this.input = ref}
					type="text"
					className="Select-input"
					placeholder={!selectedOption ? inputPlaceholder || placeholder || i18n("components", "search") : undefined}
					defaultValue={inputValue}
					required={required}
					readOnly={readOnly}
					disabled={disabled}
					onFocus={!disabled && !readOnly ? this._onInputFocus : undefined}
					onInput={!disabled && !readOnly ? this._onInput : undefined}
				/>
				<input
					type="hidden"
					name={name}
					value={!!selectedOption ? selectedOption.value : ""}
					required={required}
					readOnly={readOnly}
					disabled={disabled}
				/>
				<div ref={ref => this.dropdownContainerElement = ref} className="Select-dropdown-container">
					<div
						ref={ref => this.dropdownElement = ref}
						className="Select-dropdown"
						style={state.dropdownRect && {
							width: state.dropdownRect.width,
							top: state.dropdownRect.top,
							left: state.dropdownRect.left,
						}}
						onClick={!disabled && !readOnly ? this._onDropdownClick : undefined}
					>
						{!!options && options.length > 0 && options.map((option, index) => {
							const renderOption = option.renderOption || props.renderOption;
							return (
								<div
									key={`Select-dropdown-option-${index}`}
									className={utils.createClassName("Select-dropdown-option", {
										"highlight": highlightedIndex === index,
										"disabled": option.disabled === true,
										"selected": selectedIndex === index,
										"custom": !!renderOption
									})}
									title={option.title || option.label}
								>
									{renderOption ? renderOption(option, index) : (option.label || option.value)}
								</div>
							);
						})}

						{(!options || options.length === 0) && (
							<div className="Select-dropdown-option not-found disabled"
								 title={i18n("components", "no_results_found")}>
								{i18n("components", "no_results_found")}
							</div>
						)}
					</div>
				</div>
				{selectedOption !== null ? (
					<div className="Select-status material-icons">done</div>
				) : highlightErrors ? (
					<div className="Select-status material-icons">error</div>
				) : (
					<div className="Select-arrow material-icons">keyboard_arrow_down</div>
				)}
			</div>
		);
	}

	// Internal methods
	_onInfoClick = () => this.input.focus()

	_onInputFocus = () => {
		const {containerElement, dropdownContainerElement} = this;
		const dropdownRect = dropdownContainerElement.getBoundingClientRect();

		this.setState({focused: true, dropdownRect});

		// Body click event
		if (!this._bodyClickEvent) {
			document.body.addEventListener("click", this._bodyClickEvent = e => {
				const closestElement = utils.getClosestElementByQuery(e.target, ".Select");
				const isAncestor = !!closestElement && (closestElement === containerElement || containerElement.contains(closestElement));

				if (!isAncestor) this._onBlur.call(this);
			}, false);
		}

		// Body key up event
		if (!this._bodyKeyUpEvent) {
			document.body.addEventListener("keyup", this._bodyKeyUpEvent = e => this._onKeyPress.call(this, e), false);
		}

		// Document scroll event
		if (!this._documentScrollEvent) {
			document.addEventListener("scroll", this._documentScrollEvent = e => {
				this.setState({dropdownRect: dropdownContainerElement.getBoundingClientRect()});
			}, false);
		}

		// Window resize event
		if (!this._windowResizeEvent) {
			window.addEventListener("resize", this._windowResizeEvent = e => {
				this.setState({dropdownRect: dropdownContainerElement.getBoundingClientRect()});
			}, false);
		}
	}

	_onBlur = () => {
		const {props} = this;
		const newState = {
			options: props.options,
			focused: false,
			inputValue: "",
			highlightedIndex: -1
		};

		if (this._bodyClickEvent) {
			document.body.removeEventListener("click", this._bodyClickEvent);
			delete this._bodyClickEvent;
		}

		if (this._bodyKeyUpEvent) {
			document.body.removeEventListener("keyup", this._bodyKeyUpEvent);
			delete this._bodyKeyUpEvent;
		}

		if (this._documentScrollEvent) {
			document.removeEventListener("scroll", this._documentScrollEvent);
			delete this._documentScrollEvent;
		}

		if (this._windowResizeEvent) {
			window.removeEventListener("resize", this._windowResizeEvent);
			delete this._windowResizeEvent;
		}

		this.setState(newState);
	}

	_onInput = (e) => {
		const {props, state} = this;
		const options = _getOptions.call(this);
		const inputValue = this.input.value;
		const newState = {
			options,
			inputValue,
			highlightedIndex: options.length > 0 ? 0 : -1,
		};

		// Temporary solution for a weird reference issue when using indexOf method
		if (state.selectedOption) {
			options.forEach((o, i) => {
				if (
					state.selectedOption.value === o.value &&
					state.selectedOption.label === o.label &&
					state.selectedOption.disabled === o.disabled
				) newState.selectedIndex = i;
			});
		} else {
			newState.selectedIndex = -1;
		}
		// newState.selectedIndex = options.indexOf(state.selectedOption);

		this.setState(newState, () => {
			const {onInput} = props;
			if (onInput) onInput(inputValue);
		});
	}

	_onDropdownClick = (e) => {
		const {props, state} = this;
		const closestOptionElement = utils.getClosestElementByQuery(e.target, ".Select-dropdown-option");
		const selectedFilteredIndex = (closestOptionElement && !closestOptionElement.classList.contains("disabled") ?
				Array.from(closestOptionElement.parentNode.children).indexOf(closestOptionElement) :
				-1
		);

		if (selectedFilteredIndex >= 0) {
			const selectedOption = state.options[selectedFilteredIndex];
			const selectedIndex = props.options.indexOf(selectedOption);

			this.setState({selectedIndex, selectedOption: state.options[selectedFilteredIndex]}, () => {
				if (props.onChange) props.onChange(state.options[selectedFilteredIndex], selectedIndex);
			});
		}

		// Call onBlur event
		this._onBlur();
	}

	_onKeyPress = (e) => {
		const {key, target} = e;
		const {props, state} = this;
		const {options} = state;
		const newState = {};
		let newStateCallback;

		if (key === "Tab" && target !== this.input) {
			this.setState({focused: false, inputValue: "", highlightedIndex: -1});
		} else if (key === "ArrowUp") {
			const {selectedIndex, highlightedIndex: prevHighlightedIndex} = state;
			const highlightedIndex = newState.highlightedIndex = Math.max((prevHighlightedIndex >= 0 ? prevHighlightedIndex : selectedIndex) - 1, 0);

			newStateCallback = () => {
				const selectedOptionElement = this.dropdownElement.children[highlightedIndex];
				_ensureOptionInView.call(this, selectedOptionElement);
			};
		} else if (key === "ArrowDown") {
			const {selectedIndex, highlightedIndex: prevHighlightedIndex} = state;
			const highlightedIndex = newState.highlightedIndex = Math.min((prevHighlightedIndex >= 0 ? prevHighlightedIndex : selectedIndex) + 1, options.length - 1);

			newStateCallback = () => {
				const selectedOptionElement = this.dropdownElement.children[highlightedIndex];
				_ensureOptionInView.call(this, selectedOptionElement);
			};
		} else if (key === "Enter") {
			const {options: optionsAll} = props;
			const {highlightedIndex: prevHighlightedIndex} = state;
			const selectedOption = options[prevHighlightedIndex] || null;
			const newSelectedIndex = optionsAll.indexOf(selectedOption);

			newState.options = optionsAll;
			newState.focused = false;
			newState.inputValue = "";
			newState.highlightedIndex = -1;
			newState.selectedIndex = newSelectedIndex;
			newState.selectedOption = selectedOption;

			newStateCallback = () => {
				const {onChange} = props;
				if (onChange) onChange(selectedOption, newSelectedIndex);
			};
		}

		this.setState(newState, newStateCallback);

		// Remove event listener from body
		if (target !== this.input) {
			document.body.removeEventListener("keyup", this._bodyKeyUpEvent);
			delete this._bodyKeyUpEvent;
		}
	}
}


// PRIVATE FUNCTIONS
function _getOptions() {
	const {props, input: {value: input}} = this;
	const {options: optionsAll} = props;

	if (input.length > 0) {
		const filterer = (option) => option.label.toLowerCase().indexOf(input.toLowerCase()) === 0;
		const customFilterer = props.customFilterer ? (option, index, options) => props.customFilterer({
			option,
			index,
			options
		}, input) : null;

		return optionsAll.filter(customFilterer || filterer);
	} else {
		return optionsAll;
	}
}

function _ensureOptionInView(optionElement) {
	const {dropdownElement} = this;

	// Determine dropdownElement top and bottom
	const cTop = dropdownElement.scrollTop;
	const cBottom = cTop + dropdownElement.clientHeight;

	// Determine optionElement top and bottom
	const eTop = optionElement.offsetTop;
	const eBottom = eTop + optionElement.clientHeight;

	// Check if out of view
	if (eTop < cTop) {
		dropdownElement.scrollTop -= (cTop - eTop);
	} else if (eBottom > cBottom) {
		dropdownElement.scrollTop += (eBottom - cBottom);
	}
}