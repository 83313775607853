import { useState, useEffect, useRef } from "react";
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import { formatSSN } from "@helpers/utils";

import useResponsive from "@hooks/useResponsive";

const useSessionsTable = props => {
  const { 
    data,
    totalCases,
    user,
    partner,
    faAuth,
    type,
    modalWidget,
    caseFilter,
    setCaseFilter,
    getCases,
    isLoadingCases,
    onShareOpen,
    onAction,
    onMessage,
    currentAction,
    advisor,
    caseFilterContext,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions
  } = props;
  
  const isLaptopOrDesktop = useResponsive("up", "md");
  const [mounted, setMounted] = useState(false);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [fetchingRowContent, setFetchingRowContent] = useState(false);
  const [copyList, setCopyList] = useState(data);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [numCases, setNumCases] = useState(totalCases);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const searchTimeout = useRef(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("changed");

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    cobOptions?.rowsPerPage["sessions"] || 10
  );

  const tabs = [
    {
      key: "all",
      translationKey: "all"
    },
    {
      key: "draft",
      translationKey: "drafts",
      color: "#F5F5F5"
    },
    {
      key: "sent",
      translationKey: "sent",
      color: "#FFFBCE"
    },
    {
      key: "signed",
      translationKey: "signed",
      color: "#D1FFC8"
    },
    {
      key: "processing",
      translationKey: "in progress",
      color: "#E0F8FF"
    },
    {
      key: "recalled",
      translationKey: "revoked",
      color: "#FFD5D5"
    },
    {
      key: "archived",
      translationKey: "archived",
      color: "#FFFBCE"
    }
  ];

  const resetPaginationAndFilters = () => {
    // if there's no change to page and searchString trigger case list fetch manually
    if (page === 0 && searchString === "") fetchCases(0, "", "desc", "changed");
    
    setPage(0);
    setSearchString("");
    setCaseFilter("sessions", "all");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateCustomers = customer => {
    //update all data records that match the ssn
    let updatedData = data.map(c => {
      if (c.ssn === customer.ssn) {
        return {
          ...c,
          name: customer.name
        };
      }
      return c;
    });
    
    // sort alphabetically
    updatedData.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
    );

    // if a customer is selected, update it (edit from customer modal)
    if (selectedCustomer) setSelectedCustomer(customer);

    return setCopyList(updatedData);
  };

  const requestSearch = searched => {
      setSearchString(searched.toLowerCase());
  };

  const toggleAddCustomerModal = action => {
    if (!customerModalOpen && (action === "add" || addCustomerModalOpen)) setSelectedCustomer(null);

    if (addCustomerModalOpen) { // disable/enable background content scrolling while modal is open    
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCustomerModalOpen(!addCustomerModalOpen);
  };

  const handleClientClick = ssn => async e => {
    e.preventDefault();

    const { client } = await api("/client/relationship", { ssn: formatSSN(ssn, {withDash: true}) });

    setCustomerState({
      isCompany: false,
      ssnOrg: client.id ? (client.id).replace("-", "") : "",
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      customerModalIsOpen: true,
    }, () =>
		{
      setCustomerModalOpen(true);
      setSelectedCustomer(client);
      document.documentElement.style.overflow = "hidden";
		});
  };

  const handleClientModalClose = () => {
    setCustomerState({
      isCompany: false,
      ssnOrg: "",
      customerModalIsOpen: false
    }, () =>
		{
      setCustomerModalOpen(false);
      setSelectedCustomer(null);
		});
  };

  const handleEntryClick = async caseId => {
    // Find the index of the clicked case
    const index = copyList.findIndex(object => {
      return object["caseId"] === caseId;
    });

    // Toggle the clicked case
    let updatedData = [...copyList];
    updatedData[index].toggled = !copyList[index].toggled;
    updatedData[index].loading = true;
    setCopyList(updatedData);
    
    // If the case is toggled, fetch the case details
    if (copyList[index].toggled) {
      // Disable tabs while fetching case details otherwise setCopyList will be called out of time
      setFetchingRowContent(true);

      try
      {
        const caseDetails = await api("/case/details", {
          caseId: caseId,
          user: user,
        });
        updatedData[index].loading = false;
        updatedData[index] = { ...updatedData[index], ...caseDetails};
        setCopyList(updatedData);
        setFetchingRowContent(false);
      }
      catch (error)
      {
        setFetchingRowContent(false);
        setError(true);
        console.error(error);
      }
    }
  };
  
  const fetchCases = async (page, searchString, order, orderBy) => {
    setLoaded(false);
    const cases = await getCases(page, searchString, order, orderBy);
    setCopyList(cases.batch);
    setNumCases(parseInt(cases.total));
    setLoaded(true);
  }

  // inital load and page/rowsPerPage changes useEffect
  useEffect(() => {   
    // (ideally we would get rid of this but will need reactoring work on the DashboardSessions component)
    if (!mounted) {      
      setLoaded(true);
      setMounted(true);
    }

    if (mounted) {
      fetchCases(page, searchString, order, orderBy);
    }
  }, [page, rowsPerPage, order, orderBy])
  
  // Search useEffect
  useEffect(() => {   
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchCases(page, searchString, order, orderBy);        
      }, 2000);
    }
  }, [searchString])

  // TAB(data) change useEffect
  useEffect(() => {   
    if (mounted) {            
      setOrder("desc");
      setOrderBy("changed"); // default sort - setOrder/setOrderBy trigger adds double or triple (searchString !== "") spending when changing tabs, optimise later!
      
      setCopyList(data);
      setNumCases(totalCases);
    }
  }, [data])
  
  // CASES loading useEffect
  useEffect(() => {   
    if (mounted) {
      setLoaded(!isLoadingCases);
    }
  }, [isLoadingCases])


  return {
    user,
    partner,
    advisor,
    isLaptopOrDesktop,
    type,
    modalWidget,
    onMessage,
    onShareOpen,
    error,
    loaded,
    tabs,
    fetchingRowContent,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    selectedCustomer,
    customerModalOpen,
    addCustomerModalOpen,
    setRowsPerPage,
    setSelectedCustomer,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    handleEntryClick,
    onAction,
    caseFilter,
    setCaseFilter,
    currentAction,
    resetPaginationAndFilters,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions
  };
};

export default useSessionsTable;