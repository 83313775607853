import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName, localeNumber, decodeBase64Document} from "@helpers/utils";
import fulfillment from "./KYC.fulfillment";
import i18n from "../../../../../helpers/i18n";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextField from "../../../../../components/TextField/TextField";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import PepQuestions from "../../../components/PepQuestions/PepQuestions";
import CountrySelection from "../../../components/CountrySelection/CountrySelection";
import FileDrop from "../../../../../components/FileDrop/FileDrop";
import {DocsList} from "../../../../../components/DocsList/DocsList";
import Select from "../../../../../components/Select/Select";
import IdentityDocument from "../../../components/IdentityDocument/IdentityDocument";

export default class KYC extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static firstDepositOptions = [
		{label: "0 - 100 000 kr", value: "0 - 100 000 kr"},
		{label: "100 001 - 500 000 kr", value: "100 001 - 500 000 kr"},
		{label: "500 001 - 1 000 000 kr", value: "500 001 - 1 000 000 kr"},
		{label: "1 000 001 - 5 000 000 kr", value: "1 000 001 - 5 000 000 kr"},
		{label: "Över 5 000 000 kr", value: "Över 5 000 000 kr"}
	];

	static yearlyDepositOptions = [
		{label: "Endast engångsinsättning i samband med öppnandet av kontot", value: "Endast engångsinsättning"},
		{label: "0 - 100 000 kr", value: "0 - 100 000 kr"},
		{label: "100 001 - 500 000 kr", value: "100 001 - 500 000 kr"},
		{label: "500 001 - 1 000 000 kr", value: "500 001 - 1 000 000 kr"},
		{label: "1 000 001 - 5 000 000 kr", value: "1 000 001 - 5 000 000 kr"},
		{label: "Över 5 000 000 kr", value: "Över 5 000 000 kr"}
	];

	static transactionSizeOptions = [
		{label: "0 - 100 000 kr", value: "0 - 100 000 kr"},
		{label: "100 001 - 500 000 kr", value: "100 001 - 500 000 kr"},
		{label: "500 001 - 1 000 000 kr", value: "500 001 - 1 000 000 kr"},
		{label: "1 000 001 - 5 000 000 kr", value: "1 000 001 - 5 000 000 kr"},
		{label: "Över 5 000 000 kr", value: "Över 5 000 000 kr"}
	];

	static occupationOptions = [
		{label: "Anställd", value: "Anställd"},
		{label: "Egen företagare", value: "Egen företagare"},
		{label: "Pensionär", value: "Pensionär"},
		{label: "Studerande", value: "Studerande"},
		{label: "Annat", value: "Annat"}
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState, hasFormValidationErrors, readOnly} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);

		const purposeCombined = [];

		if (customerState.kyc_purpose_savings === "yes")
		{
			purposeCombined.push("Sparande/placering");
		}

		if (customerState.kyc_purpose_security === "yes")
		{
			purposeCombined.push("Ekonomisk trygghet");
		}

		if (customerState.kyc_purpose_pension === "yes")
		{
			purposeCombined.push("Pension");
		}

		if (customerState.kyc_purpose_consumption === "yes")
		{
			purposeCombined.push("Privat konsumtion");
		}

		if (customerState.kyc_purpose_gift === "yes")
		{
			purposeCombined.push("Gåva till närstående");
		}

		if (customerState.kyc_purpose_beneficiary === "yes")
		{
			purposeCombined.push("Förmånstagare");
		}

		if (customerState.kyc_purpose_other === "yes")
		{
			purposeCombined.push("Annat: " + customerState.kyc_purpose_other_details);
		}

		const srcFundsCombined = [];

		if (customerState.kyc_src_funds_salary_pension === "yes")
		{
			srcFundsCombined.push("Lön/Pension");
		}

		if (customerState.kyc_src_funds_savings === "yes")
		{
			srcFundsCombined.push("Sparande/placering");
		}

		if (customerState.kyc_src_funds_exit === "yes")
		{
			srcFundsCombined.push("Bolagsförsäljning");
		}

		if (customerState.kyc_src_funds_gift_inheritance === "yes")
		{
			srcFundsCombined.push("Arv/Gåva");
		}

		if (customerState.kyc_src_funds_real_estate === "yes")
		{
			srcFundsCombined.push("Bostads- eller fastighetsförsäljning");
		}

		if (customerState.kyc_src_funds_other === "yes")
		{
			srcFundsCombined.push("Annat: " + customerState.kyc_src_funds_other_details);
		}


		const srcCombined = [];

		if (customerState.kyc_src_bank_sweden === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut i Sverige");
		}

		if (customerState.kyc_src_insurance_sweden === "yes")
		{
			srcCombined.push("Försäkringsbolag i Sverige");
		}

		if (customerState.kyc_src_bank_eu === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut i annat land inom EU/EES");
		}

		if (customerState.kyc_src_insurance_eu === "yes")
		{
			srcCombined.push("Försäkringsbolag i annat land inom EU/EES");
		}

		if (customerState.kyc_src_bank_world === "yes")
		{
			srcCombined.push("Bank eller värdepappersinstitut utanför EU/EES");
		}

		if (customerState.kyc_src_insurance_world === "yes")
		{
			srcCombined.push("Försäkringsbolag utanför EU/EES");
		}

		let investOnBehalfOptions = [
			{label: "För egen del", value: "För egen del"},
			{label: "För någon annan genom fullmakt", value: "För någon annan genom fullmakt"},
			{label: "Annat (beskriv)", value: "Annat"}
		];

		let incomeOptions = [
			{label: "0 - 20 000 kr", value: "0 - 20000 kr"},
			{label: "20 001 - 50 000 kr", value: "20 001 - 50 000 kr"},
			{label: "50 001 - 100 000 kr", value: "50 001 - 100 000 kr"},
			{label: "Över 100 000 kr", value: "Över 100 000 kr"}
		];

		let depositFrequencyOptions = [
			{label: "0 - 10 gånger", value: "0 - 10 gånger"},
			{label: "11 - 50 gånger", value: "11 - 50 gånger"},
			{label: "51 - 100 gånger", value: "51 - 100 gånger"},
			{label: "Fler än 100 gånger", value: "Fler än 100 gånger"}
		];

		let withdrawalFrequencyOptions = [
			{label: "0 - 10 gånger", value: "0 - 10 gånger"},
			{label: "11 - 50 gånger", value: "11 - 50 gånger"},
			{label: "51 - 100 gånger", value: "51 - 100 gånger"},
			{label: "Fler än 100 gånger", value: "Fler än 100 gånger"}
		];

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Kundkännedom</h2>

					<div className="Cob-view2-fields">
						<h3>Medborgarskap och skattehemvist</h3>

						<StackLayout className="Cob-fields-collection per-row-3 align-start" rowWrap fullWidth>
							<div>
								<CountrySelection
                  label={i18n("general", "citizenship")}
                  field={"kyc_citizenship"}
                  selected={customerState.citizenship}
                  mandatory={true}
                  onChange={(value) => this.setCustomerState({citizenship: value.label})}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
                />

								{state.citizenshipCount === 1 && (
									<React.Fragment>
										<span onClick={this._addCitizenship}
											  style={{
												  margin: "5px 2px 0 0",
												  fontSize: "11px",
												  fontWeight: "bold",
												  textDecoration: "underline",
												  cursor: "pointer",
												  alignSelf: "end"
											  }}>+ Lägg till fler medborgarskap</span>
									</React.Fragment>
								)}
							</div>
							<div>
								<CountrySelection
                  label={i18n("general", "tax_country")}
                  field={"kyc_tax_country"}
                  selected={customerState.taxCountry}
                  mandatory={true}
                  onChange={(value) => this.setCustomerState({taxCountry: value.label})}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
                />
							</div>
						</StackLayout>

						{state.citizenshipCount === 1 && (
							<input type={"hidden"} name={"kyc_citizenship_2"} value={"-"}/>
						)}

						{state.citizenshipCount === 2 && (
							<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
								<div>
									<CountrySelection 
                    label={i18n("general", "citizenship")}
                    field={"kyc_citizenship_2"}
                    selected={customerState.citizenship_2}
                    mandatory={true}
                    onChange={(value) => this.setCustomerState({citizenship_2: value.label})}
                    highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
                  />
									<span onClick={this._removeCitizenship}
										  style={{
											  margin: "5px 2px 0 0",
											  fontSize: "11px",
											  fontWeight: "bold",
											  textDecoration: "underline",
											  cursor: "pointer",
											  alignSelf: "end"
										  }}>Ta bort medborgarskap</span>
								</div>
							</StackLayout>
						)}
						<IdentityDocument
							customer={this.props.customer}
							updatedDocument={this._onDocumentUpdated}
							companyMode={false}
							hasValidIdentityDocument={this.props.customer.hasValidIdentityDocument()}
							identityDocumentAdded={this.props.customer.identityDocumentAdded}
							identityDocumentNumber={this.props.customer.identityDocumentNumber}
							identityDocumentExpiration={this.props.customer.identityDocumentExpiration}              
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />
						<div className="h-line spacing"/>

						<h3>Utlandet</h3>
						<StackLayout className="Cob-fields-collection per-row-2 align-start" rowWrap fullWidth>
							<div>
								<OptionsList
									style={{marginTop: "10px"}}
									type="radio"
									name="rdo_kyc_usperson"
                  value={customerState.rdo_kyc_usperson}
									description="Har du någon relation till USA?*"
									options={[
										{label: "Nej", value: "Nej", checked: customerState.rdo_kyc_usperson === "Nej"},
										{label: "Ja", value: "Ja", checked: customerState.rdo_kyc_usperson === "Ja"}
									]}
                  validation={value => (value && value.length > 0) || false}
									onChange={(checked, rdo_kyc_usperson) => this.setCustomerState({rdo_kyc_usperson})}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<OptionsList
									style={{marginTop: "10px"}}
									type="radio"
									name="rdo_kyc_inttrans"
                  value={customerState.rdo_kyc_inttrans}
									description="Gör du som regel transaktioner med utlandet?*"
									options={[
										{label: "Nej", value: "Nej", checked: customerState.rdo_kyc_inttrans === "Nej"},
										{label: "Ja", value: "Ja", checked: customerState.rdo_kyc_inttrans === "Ja"}
									]}
                  validation={value => (value && value.length > 0) || false}
									onChange={(checked, rdo_kyc_inttrans) => this.setCustomerState({rdo_kyc_inttrans})}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>

						{(customerState.rdo_kyc_usperson === "Ja" || customerState.rdo_kyc_inttrans === "Ja") && (
							<React.Fragment>
								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>Beskriv kundens relation
									till USA och/eller omfattning av transaktioner med utlandet*</h4>
								<TextView
									name="kyc_us_or_foreign_text"
									label={`Ange (${500 - customerState.kyc_us_or_foreign_text.length} tecken kvar)`}
									value={customerState.kyc_us_or_foreign_text}
									maxLength={500}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({kyc_us_or_foreign_text: target.value})}
									fullWidth
									minHeight={100}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>Ladda upp dokument
									(valfritt)</h4>
								<div style={{maxWidth: "500px", width: "100%"}}>
									<FileDrop
										filterKey={"us-foreign"}
										dropText="Dra och släpp dokument här"
										busyText="Laddar in dokument..."
										accept="image/png, image/jpeg, application/pdf"
										onDrop={this._onDocDrop}
										style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
									/>

									<DocsList
										filterKey={"us-foreign"}
										items={state.docs}
										totalSize={totalDocsSize}
										maxSize={maxUploadFileSize}
										onDocRemove={this._onDocRemove}
										alignBottom={true}
									/>
								</div>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>
						<h3>Syfte med engagemang</h3>

						<OptionsList
							style={{marginTop: "10px"}}
							type="checkbox"
              value={
                customerState.kyc_purpose_savings === "yes" ||
                customerState.kyc_purpose_security === "yes" ||
                customerState.kyc_purpose_pension === "yes" ||
                customerState.kyc_purpose_consumption === "yes" ||
                customerState.kyc_purpose_gift === "yes" ||
                customerState.kyc_purpose_beneficiary === "yes" ||
                customerState.kyc_purpose_other === "yes" ?
                 "filled" :
                 ""
              }
							description="Vad är syftet med ditt sparande och/eller dina placeringar?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_purpose_savings",
									label: "Sparande/placering",
									value: customerState.kyc_purpose_savings === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_savings === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_savings: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_security",
									label: "Ekonomisk trygghet",
									value: customerState.kyc_purpose_security === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_security === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_security: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_pension",
									label: "Pension",
									value: customerState.kyc_purpose_pension === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_pension === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_pension: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_consumption",
									label: "Privat konsumtion",
									value: customerState.kyc_purpose_consumption === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_consumption === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_consumption: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_gift",
									label: "Gåva till närstående",
									value: customerState.kyc_purpose_gift === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_gift === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_gift: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_beneficiary",
									label: "Förmånstagare",
									value: customerState.kyc_purpose_beneficiary === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_beneficiary === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_beneficiary: checked ? "yes" : "no"})
								},
								{
									name: "kyc_purpose_other",
									label: "Annat",
									value: customerState.kyc_purpose_other === "yes" ? "yes" : "no",
									checked: customerState.kyc_purpose_other === "yes",
									onChange: checked => this.setCustomerState({kyc_purpose_other: checked ? "yes" : "no"})
								}
							]}
              validation={value => (value && value === "filled") || false}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						{customerState.kyc_purpose_other === "yes" && (
							<TextField
								name="kyc_purpose_other_details"
								value={customerState.kyc_purpose_other_details}
								label={"Vänligen beskriv"}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({kyc_purpose_other_details: target.value})}
                highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						)}

						<input type={"hidden"} name={"kyc_purpose_savings_combined"}
							   value={purposeCombined.join(", ")}/>

						<strong style={{marginTop: "20px"}}>För vems räkning investerar du?*</strong>
						<Select
              label="Välj"
              name={"kyc_invest_on_behalf"}
              selectedIndex={investOnBehalfOptions.map(c => c.value).indexOf(customerState.kyc_invest_on_behalf)}
              options={investOnBehalfOptions}
              onChange={(value) => this.setCustomerState({kyc_invest_on_behalf: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						{customerState.kyc_invest_on_behalf === "Annat" && (
							<TextView
								name="kyc_invest_on_behalf_other"
								label={`Vänligen beskriv (${200 - customerState.kyc_invest_on_behalf_other.length} tecken kvar)`}
								value={customerState.kyc_invest_on_behalf_other}
								maxLength={200}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({kyc_invest_on_behalf_other: target.value})}
								fullWidth
								minHeight={100}
                highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						)}

						{customerState.kyc_invest_on_behalf === "För någon annan genom fullmakt" && (
							<React.Fragment>
								<strong style={{marginTop: "20px"}}>Kommentar till fullmakten*</strong>
								<TextView
									name="kyc_invest_on_behalf_poa"
									label={`Vänligen beskriv (${200 - customerState.kyc_invest_on_behalf_poa.length} tecken kvar)`}
									value={customerState.kyc_invest_on_behalf_poa}
									maxLength={200}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({kyc_invest_on_behalf_poa: target.value})}
									fullWidth
									minHeight={100}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<strong style={{marginTop: "20px"}}>Ladda upp dokument som styrker fullmakt*</strong>

								<div style={{maxWidth: "500px", width: "100%"}}>
									<FileDrop
										filterKey={"poa-copy"}
										dropText="Dra och släpp dokument här"
										busyText="Laddar in dokument..."
										accept="image/png, image/jpeg, application/pdf"
										onDrop={this._onDocDrop}
										style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
									/>

									<DocsList
										filterKey={"poa-copy"}
										items={state.docs}
										totalSize={totalDocsSize}
										maxSize={maxUploadFileSize}
										onDocRemove={this._onDocRemove}
										alignBottom={true}
										required={true}
									/>
								</div>
							</React.Fragment>

						)}

						<div className="h-line spacing"/>
						<h3>Huvudsaklig sysselsättning</h3>

						<strong style={{marginTop: "20px"}}>Vilken är din huvudsakliga sysselsättning?*</strong>
						<Select 
              label="Välj"
              name={"kyc_occupation"}
              selectedIndex={KYC.occupationOptions.map(c => c.value).indexOf(customerState.kyc_occupation)}
              options={KYC.occupationOptions}
              onChange={(value) => this.setCustomerState({kyc_occupation: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						{(customerState.kyc_occupation === "Anställd" || customerState.kyc_occupation === "Egen företagare") && (
							<React.Fragment>
								<p className={"Cob-OptionsList-description"}>
									Vilken bransch?
								</p>

								<TextView
									name="kyc_occupation_details"
									label={`Fritext (${200 - customerState.kyc_occupation_details.length} tecken kvar)`}
									value={customerState.kyc_occupation_details}
									maxLength={200}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({kyc_occupation_details: target.value})}
									fullWidth
									minHeight={90}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<input type={"hidden"} name={"kyc_occupation"}
									   value={customerState.kyc_occupation + ": " + customerState.kyc_occupation_details}/>
							</React.Fragment>
						)}

						<strong style={{marginTop: "20px"}}>Vilken ungefärlig månadsinkomst har du?*</strong>
						<Select 
              label="Välj"
              name={"kyc_income"}
              selectedIndex={incomeOptions.map(c => c.value).indexOf(customerState.kyc_income)}
              options={incomeOptions}
              onChange={(value) => this.setCustomerState({kyc_income: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						<div className="h-line spacing"/>
						<h3>Kapitalöverföring</h3>

						<OptionsList
							style={{marginTop: "10px"}}
							type="radio"
							name="kyc_owner_of_funds"
              value={customerState.kyc_owner_of_funds}
							description={"Är det dina pengar som kommer att överföras?*"}
							options={[
								{
									label: "Nej",
									value: "Nej",
									checked: customerState.kyc_owner_of_funds === "Nej"
								},
								{
									label: "Ja",
									value: "Ja",
									checked: customerState.kyc_owner_of_funds === "Ja"
								}
							]}
              validation={value => (value && value.length > 0) || false}
							onChange={(checked, kyc_owner_of_funds) => this.setCustomerState({kyc_owner_of_funds})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						{(customerState.kyc_owner_of_funds === "Nej") && (
							<React.Fragment>
								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>Förklara vems pengar som
									kommer att överföras</h4>
								<TextView
									name="kyc_owner_of_funds_text"
									label={`Ange (${500 - customerState.kyc_owner_of_funds_text.length} tecken kvar)`}
									value={customerState.kyc_owner_of_funds_text}
									maxLength={500}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({kyc_owner_of_funds_text: target.value})}
									fullWidth
									minHeight={100}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 className={"bottom-margin"} style={{paddingTop: "15px"}}>Ladda upp dokument
									(valfritt)</h4>
								<div style={{maxWidth: "500px", width: "100%"}}>
									<FileDrop
										filterKey={"owner-of-funds"}
										dropText="Dra och släpp dokument här"
										busyText="Laddar in dokument..."
										accept="image/png, image/jpeg, application/pdf"
										onDrop={this._onDocDrop}
										style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
									/>

									<DocsList
										filterKey={"owner-of-funds"}
										items={state.docs}
										totalSize={totalDocsSize}
										maxSize={maxUploadFileSize}
										onDocRemove={this._onDocRemove}
										alignBottom={true}
									/>
								</div>
							</React.Fragment>
						)}

						<OptionsList
							style={{marginTop: "20px"}}
							type="checkbox"
              value={
                customerState.kyc_src_funds_salary_pension === "yes" ||
                customerState.kyc_src_funds_savings === "yes" ||
                customerState.kyc_src_funds_exit === "yes" ||
                customerState.kyc_src_funds_gift_inheritance === "yes" ||
                customerState.kyc_src_funds_real_estate === "yes" ||
                customerState.kyc_src_funds_other === "yes" ?
                "filled" :
                ""
              }
							description="Varifrån kommer de pengar och/eller värdepapper som du sätter in på Citroneer?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_src_funds_salary_pension",
									label: "Lön/Pension",
									value: customerState.kyc_src_funds_salary_pension === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_salary_pension === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_salary_pension: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_savings",
									label: "Sparande/placering",
									value: customerState.kyc_src_funds_savings === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_savings === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_savings: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_exit",
									label: "Bolagsförsäljning",
									value: customerState.kyc_src_funds_exit === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_exit === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_exit: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_gift_inheritance",
									label: "Arv/Gåva",
									value: customerState.kyc_src_funds_gift_inheritance === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_gift_inheritance === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_gift_inheritance: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_real_estate",
									label: "Bostads- eller fastighetsförsäljning",
									value: customerState.kyc_src_funds_real_estate === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_real_estate === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_real_estate: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_funds_other",
									label: "Annat",
									value: customerState.kyc_src_funds_other === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_funds_other === "yes",
									onChange: checked => this.setCustomerState({kyc_src_funds_other: checked ? "yes" : "no"})
								}
							]}
              validation={value => (value && value === "filled") || false}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						{customerState.kyc_src_funds_other === "yes" && (
							<TextView
								name="kyc_src_funds_other_details"
								label={`Vänligen beskriv (${200 - customerState.kyc_src_funds_other_details.length} tecken kvar)`}
								value={customerState.kyc_src_funds_other_details}
								maxLength={200}
								validation={value => value.length > 0}
								resize="vertical"
								onChange={({target}) => this.setCustomerState({kyc_src_funds_other_details: target.value})}
								fullWidth
								minHeight={100}
                highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						)}

						<input type={"hidden"} name={"kyc_src_funds_combined"} value={srcFundsCombined.join(", ")}/>

						<OptionsList
							style={{marginTop: "20px"}}
							type="checkbox"
              value={
                customerState.kyc_src_bank_sweden === "yes" ||
                customerState.kyc_src_insurance_sweden === "yes" ||
                customerState.kyc_src_bank_eu === "yes" ||
                customerState.kyc_src_insurance_eu === "yes" ||
                customerState.kyc_src_bank_world === "yes" ||
                customerState.kyc_src_insurance_world === "yes" ?
                "filled" :
                ""
              }
							description="Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?*"
							orientation="vertical"
							options={[
								{
									name: "kyc_src_bank_sweden",
									label: "Bank eller värdepappersinstitut i Sverige",
									value: customerState.kyc_src_bank_sweden === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_sweden === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_sweden: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_sweden",
									label: "Försäkringsbolag i Sverige",
									value: customerState.kyc_src_insurance_sweden === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_sweden === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_sweden: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_bank_eu",
									label: "Bank eller värdepappersinstitut i annat land inom EU/EES",
									value: customerState.kyc_src_bank_eu === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_eu === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_eu: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_eu",
									label: "Försäkringsbolag i annat land inom EU/EES",
									value: customerState.kyc_src_insurance_eu === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_eu === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_eu: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_bank_world",
									label: "Bank eller värdepappersinstitut utanför EU/EES",
									value: customerState.kyc_src_bank_world === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_bank_world === "yes",
									onChange: checked => this.setCustomerState({kyc_src_bank_world: checked ? "yes" : "no"})
								},
								{
									name: "kyc_src_insurance_world",
									label: "Försäkringsbolag utanför EU/EES",
									value: customerState.kyc_src_insurance_world === "yes" ? "yes" : "no",
									checked: customerState.kyc_src_insurance_world === "yes",
									onChange: checked => this.setCustomerState({kyc_src_insurance_world: checked ? "yes" : "no"})
								}
							]}
              validation={value => (value && value === "filled") || false}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						<input type={"hidden"} name={"kyc_src_combined"} value={srcCombined.join(", ")}/>

						<strong style={{marginTop: "20px"}}>
							Vilket ungefärligt värde kommer du att överföra i samband med öppnandet av ditt konto hos
							Citroneer?*
						</strong>
						<Select
              label="Välj"
              name={"kyc_first_deposit"}
              selectedIndex={KYC.firstDepositOptions.map(c => c.value).indexOf(customerState.kyc_first_deposit)}
              options={KYC.firstDepositOptions}
              onChange={(value) => this.setCustomerState({kyc_first_deposit: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						{customerState.kyc_first_deposit === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField
                  label="Ange estimat*"
                  unit="SEK"
                  name="kyc_first_deposit_estimate"
                  value={customerState.kyc_first_deposit_estimate}
                  showSeparators={true}
                  validation={this._validateEstimatedAmount}
                  onChange={({target}) =>
                  {
                    this.setCustomerState({kyc_first_deposit_estimate: target.value.split(' ').join('')});
                  }}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
                />
								<input type="hidden" name="kyc_first_deposit_estimate"
									   value={customerState.kyc_first_deposit_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_first_deposit"
									   value={localeNumber(customerState.kyc_first_deposit_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}

						<div className="h-line spacing"/>
						<h3>Insättningar, uttag och transaktioner</h3>

						<strong style={{marginTop: "20px"}}>
							Vilket värde kommer dina insättningar att uppgå till i genomsnitt per år?*
						</strong>
						<Select
              label="Välj"
              name={"kyc_yearly_deposit"}
              selectedIndex={KYC.yearlyDepositOptions.map(c => c.value).indexOf(customerState.kyc_yearly_deposit)}
              options={KYC.yearlyDepositOptions}
              onChange={(value) => this.setCustomerState({kyc_yearly_deposit: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						{customerState.kyc_yearly_deposit === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField 
                  label="Ange estimat*"
                  unit="SEK"
                  name="kyc_yearly_deposit_estimate"
                  value={customerState.kyc_yearly_deposit_estimate}
                  showSeparators={true}
                  validation={this._validateEstimatedAmount}
                  onChange={({target}) =>
                  {
                    this.setCustomerState({kyc_yearly_deposit_estimate: target.value.split(' ').join('')});
                  }}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
                />
								<input type="hidden" name="kyc_yearly_deposit_estimate"
									   value={customerState.kyc_yearly_deposit_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_yearly_deposit"
									   value={localeNumber(customerState.kyc_yearly_deposit_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}

						<strong style={{marginTop: "20px"}}>Hur ofta kommer insättningar att göras per år?*</strong>
						<Select 
              label="Välj"
              name={"kyc_deposit_frequency"}
              selectedIndex={depositFrequencyOptions.map(c => c.value).indexOf(customerState.kyc_deposit_frequency)}
              options={depositFrequencyOptions}
              onChange={(value) => this.setCustomerState({kyc_deposit_frequency: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						<strong style={{marginTop: "20px"}}>Hur ofta kommer uttag att göras per år?*</strong>
						<Select
              label="Välj"
              name={"kyc_withdrawal_frequency"}
              selectedIndex={withdrawalFrequencyOptions.map(c => c.value).indexOf(customerState.kyc_withdrawal_frequency)}
              options={withdrawalFrequencyOptions}
              onChange={(value) => this.setCustomerState({kyc_withdrawal_frequency: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />


						<strong style={{marginTop: "20px"}}>
							Hur stora enskilda transaktioner i finansiella instrument gör du normalt?*
						</strong>
						<Select 
              label="Välj"
              name={"kyc_transaction_size"}
              selectedIndex={KYC.transactionSizeOptions.map(c => c.value).indexOf(customerState.kyc_transaction_size)}
              options={KYC.transactionSizeOptions}
              onChange={(value) => this.setCustomerState({kyc_transaction_size: value.value})}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />

						{customerState.kyc_transaction_size === "Över 5 000 000 kr" && (
							<div style={{maxWidth: "200px", width: "100%"}}>
								<NumberField 
                  label="Ange estimat*"
                  unit="SEK"
                  name="kyc_transaction_size_estimate"
                  value={customerState.kyc_transaction_size_estimate}
                  showSeparators={true}
                  validation={this._validateEstimatedAmount}
                  onChange={({target}) =>
                  {
                    this.setCustomerState({kyc_transaction_size_estimate: target.value.split(' ').join('')});
                  }}
                  highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
                />
								<input type="hidden" name="kyc_transaction_size_estimate"
									   value={customerState.kyc_transaction_size_estimate.replace("SEK", "")}/>
								<input type="hidden" name="kyc_transaction_size"
									   value={localeNumber(customerState.kyc_transaction_size_estimate.replace("SEK", ""), 0) + " kr"}/>
							</div>
						)}

						<div className="h-line spacing"/>

						<PepQuestions
              customer={this.props.customer}
              valueInSwedish={true}
              onUpdated={(pepSelf, pepSelfType, pepRelative, pepRelativeType) =>
              {
                this.setCustomerState({kyc_is_pep_fulfilled: isPepFulfilled(pepSelf, pepSelfType, pepRelative, pepRelativeType)});
              }}
              highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
            />
					</div>
				</ContentBox>
			</div>
		);
	}

	_textValidation = (value) => (value.length > 0);

	_addCitizenship = () =>
	{
		this.setState({citizenshipCount: 2}, () => this.setCustomerState({citizenship_2: "", citizenshipIndex2: -1}));
	};

	_removeCitizenship = () =>
	{
		this.setState({citizenshipCount: 1}, () => this.setCustomerState({citizenship_2: "", citizenshipIndex2: -1}));
	};

	_validateEstimatedAmount = (value) =>
	{
		if (!value)
		{
			return false;
		}

		value = value.split(" ").join("");
		value = parseInt(value, 10);
		return !(isNaN(value) || value <= 5000000);
	};

	_onDocumentUpdated = (valid) => {
		const newState = {...this.state};
		newState.hasValidIdentityDocument = valid;
		newState.isFulfilled = this.isFulfilled(newState);
		this.setState(newState, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}

	// Internal methods
	_onDocDrop = (documents) =>
	{
		const {props, state} = this;
    if (state.readOnly) return;

		const {customer} = props;
		const docs = [...state.docs];
		const docNames = [...state.docNames];


		for (let i = 0, n = documents.length; i < n; i++)
		{
			const doc = documents[i];

			// Prevent duplicates
			if (docNames.indexOf(doc.name) !== -1)
			{
				alert('Fil med det namnet har redan bifogats ärendet och behöver inte bifogas på nytt.');
				console.log("Skipped duplicate document " + doc.name);
				continue;
			}

			docs.push({name: doc.name, size: doc.size, filterKey: doc.filterKey});
			docNames.push(doc.name);

			customer.addDocument({
				name: doc.name,
				size: doc.size,
				data: doc.data,
				filterKey: doc.filterKey
			});
		}

		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
    if (state.readOnly) return;

		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name;
		});

		customer.removeDocumentWithName(item.name);
    
		this.setState({docs, docNames, identityDocumentValid: customer.hasValidIdentityDocument(), isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors, readOnly} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
  newState.readOnly = readOnly;
	return newState;
}
export function getViewInitialStateForCustomer(customer)
{
	let newState = {
		citizenshipCount: 1,
		docs: customer.documents.map(doc => {
      // get document size from raw_data if property exists
      const decodedDoc = decodeBase64Document(doc);

      return {name: doc.name || doc.document_name, size: doc.size || decodedDoc.length, filterKey: doc.filterKey || doc.type}
    }),
		docNames: customer.documents.map(doc => doc.name || doc.document_name),
		maxUploadFileSize: customer.maxUploadFileSize,

		hasValidIdentityDocument: customer.hasValidIdentityDocument(),

		customerState: {
			citizenship: customer.getData("kyc_citizenship") || "",
			citizenship_2: customer.getData("kyc_citizenship_2") || "",
			taxCountry: customer.getData("kyc_tax_country") || "",

			rdo_kyc_usperson: customer.getData("rdo_kyc_usperson") || "",
			rdo_kyc_inttrans: customer.getData("rdo_kyc_inttrans") || "",

			kyc_purpose_savings: customer.getData("kyc_purpose_savings") || "",
			kyc_purpose_security: customer.getData("kyc_purpose_security") || "",
			kyc_purpose_pension: customer.getData("kyc_purpose_pension") || "",
			kyc_purpose_consumption: customer.getData("kyc_purpose_consumption") || "",
			kyc_purpose_gift: customer.getData("kyc_purpose_gift") || "",
			kyc_purpose_beneficiary: customer.getData("kyc_purpose_beneficiary") || "",
			kyc_purpose_other: customer.getData("kyc_purpose_other") || "",
			kyc_purpose_other_details: customer.getData("kyc_purpose_other_details") || "",

			kyc_invest_on_behalf: customer.getData("kyc_invest_on_behalf") || "",
			kyc_invest_on_behalf_other: customer.getData("kyc_invest_on_behalf_other") || "",
			kyc_invest_on_behalf_poa: customer.getData("kyc_invest_on_behalf_poa") || "",

			kyc_occupation: customer.getData("kyc_occupation") || "",
			kyc_occupation_details: customer.getData("kyc_occupation_details") || "",
			kyc_income: customer.getData("kyc_income") || "",
			kyc_owner_of_funds: customer.getData("kyc_owner_of_funds") || "",

			kyc_src_bank_sweden: customer.getData("kyc_src_bank_sweden") || "",
			kyc_src_insurance_sweden: customer.getData("kyc_src_insurance_sweden") || "",
			kyc_src_bank_eu: customer.getData("kyc_src_bank_eu") || "",
			kyc_src_insurance_eu: customer.getData("kyc_src_insurance_eu") || "",
			kyc_src_bank_world: customer.getData("kyc_src_bank_world") || "",
			kyc_src_insurance_world: customer.getData("kyc_src_insurance_world") || "",

			kyc_src_funds_salary_pension: customer.getData("kyc_src_funds_salary_pension") || "",
			kyc_src_funds_savings: customer.getData("kyc_src_funds_savings") || "",
			kyc_src_funds_exit: customer.getData("kyc_src_funds_exit") || "",
			kyc_src_funds_gift_inheritance: customer.getData("kyc_src_funds_gift_inheritance") || "",
			kyc_src_funds_real_estate: customer.getData("kyc_src_funds_real_estate") || "",
			kyc_src_funds_other: customer.getData("kyc_src_funds_other") || "",
			kyc_src_funds_other_details: customer.getData("kyc_src_funds_other_details") || "",

			kyc_us_or_foreign_text: customer.getData("kyc_us_or_foreign_text") || "",
			kyc_owner_of_funds_text: customer.getData("kyc_owner_of_funds_text") || "",

			kyc_first_deposit: customer.getData("kyc_first_deposit") || "",
			kyc_first_deposit_estimate: customer.getData("kyc_first_deposit_estimate") ? customer.getData("kyc_first_deposit_estimate").replace("SEK", "") : "",

			kyc_yearly_deposit: customer.getData("kyc_yearly_deposit") || "",
			kyc_yearly_deposit_estimate: customer.getData("kyc_yearly_deposit_estimate") ? customer.getData("kyc_yearly_deposit_estimate").replace("SEK", "") : "",

			kyc_transaction_size: customer.getData("kyc_transaction_size") || "",
			kyc_transaction_size_estimate: customer.getData("kyc_transaction_size_estimate") ? customer.getData("kyc_transaction_size_estimate").replace("SEK", "") : "",

			kyc_deposit_frequency: customer.getData("kyc_deposit_frequency") || "",
			kyc_withdrawal_frequency: customer.getData("kyc_withdrawal_frequency") || "",

			kyc_is_pep_fulfilled: isPepFulfilled(
				customer.getData("kyc_pep_self"),
				customer.getData("kyc_pep_self_type"),
				customer.getData("kyc_pep_relative"),
				customer.getData("kyc_pep_relative_type")
			)
		}
	};

	let hasIdCopy = false;
	for (let i = 0; i < customer.documents.length; i++)
	{
		if (customer.documents[i].filterKey === "passport-id" || customer.documents[i].Type === "passport-id")
		{
			newState.identityFileDropVisible = true;
			hasIdCopy = true;
			break;
		}
	}

	if (!hasIdCopy)
	{
		newState.customerState.identity_doc_number = "";
		newState.customerState.identity_doc_expiration = "";
	}

	if (newState.customerState.identity_doc_expiration != null &&
		newState.customerState.identity_doc_expiration.length > 0) {
		try
		{
			newState.customerState.identity_doc_expiration_date = new Date(newState.customerState.identity_doc_expiration);
		}
		catch (ignored)
		{
      console.log(ignored);
		}
	}

	const firstDepositIndex = KYC.firstDepositOptions.map(c => c.value).indexOf(customer.getData("kyc_first_deposit"));
	if (firstDepositIndex <= -1 && customer.getData("kyc_first_deposit_estimate"))
	{
		newState.customerState.kyc_first_deposit = "Över 5 000 000 kr";
	}

	const yearlyDepositIndex = KYC.yearlyDepositOptions.map(c => c.value).indexOf(customer.getData("kyc_yearly_deposit"));
	if (yearlyDepositIndex <= -1 && customer.getData("kyc_yearly_deposit_estimate"))
	{
		newState.customerState.kyc_yearly_deposit = "Över 5 000 000 kr";
	}

	const transSizeIndex = KYC.transactionSizeOptions.map(c => c.value).indexOf(customer.getData("kyc_transaction_size"));
	if (transSizeIndex <= -1 && customer.getData("kyc_transaction_size_estimate"))
	{
		newState.customerState.kyc_transaction_size = "Över 5 000 000 kr";
	}

	const occupationIndex = KYC.occupationOptions.map(c => c.value).indexOf(customer.getData("kyc_occupation"));
	const occupationFull = customer.getData("kyc_occupation");
	if (occupationIndex <= -1 && occupationFull)
	{
		if (occupationFull.startsWith("Anställd"))
		{
			newState.customerState.kyc_occupation = "Anställd";
		}
		else
		{
			newState.customerState.kyc_occupation = "Egen företagare";
		}
	}

	if (customer.getData("kyc_citizenship_2") && customer.getData("kyc_citizenship_2") !== "-")
	{
		newState.citizenshipCount = 2;
	}

	// Translate old value to new wording
	if (newState.customerState.kyc_invest_on_behalf === "För egen del (eller av mig helägt företag)")
	{
		newState.customerState.kyc_invest_on_behalf = "För egen del";
	}

	return newState;
}

function isPepFulfilled(pepSelf, pepSelfType, pepRelative, pepRelativeType)
{
	if (pepSelf.length <= 0 || pepRelative.length <= 0)
	{
		return false;
	}

	if ((pepSelf === "yes" || pepSelf === "Ja") && pepSelfType.length <= 0)
	{
		return false;
	}

	if ((pepRelative === "yes" || pepRelative === "Ja") && pepRelativeType.length <= 0)
	{
		return false;
	}

	return true;
}

function _getFields()
{
	return [
		"kyc_citizenship",
		"kyc_citizenship_2",
		"kyc_tax_country",

		"identity_doc_number",
		"identity_doc_expiration",

		"rdo_kyc_usperson",
		"rdo_kyc_inttrans",

		"kyc_us_or_foreign_text",
		"kyc_owner_of_funds_text",

		"kyc_purpose_savings",
		"kyc_purpose_security",
		"kyc_purpose_pension",
		"kyc_purpose_consumption",
		"kyc_purpose_gift",
		"kyc_purpose_beneficiary",
		"kyc_purpose_other",
		"kyc_purpose_other_details",

		"kyc_invest_on_behalf",
		"kyc_invest_on_behalf_other",
		"kyc_invest_on_behalf_poa",
		"kyc_occupation",
		"kyc_occupation_details",

		"kyc_src_bank_sweden",
		"kyc_src_insurance_sweden",
		"kyc_src_bank_eu",
		"kyc_src_insurance_eu",
		"kyc_src_bank_world",
		"kyc_src_insurance_world",

		"kyc_src_funds_salary_pension",
		"kyc_src_funds_savings",
		"kyc_src_funds_exit",
		"kyc_src_funds_gift_inheritance",
		"kyc_src_funds_real_estate",
		"kyc_src_funds_other",
		"kyc_src_funds_other_details",

		"kyc_first_deposit",
		"kyc_first_deposit_estimate",

		"kyc_yearly_deposit",
		"kyc_yearly_deposit_estimate",

		"kyc_transaction_size",
		"kyc_transaction_size_estimate",

		"kyc_deposit_frequency",
		"kyc_withdrawal_frequency"
	];
}