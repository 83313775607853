import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./ActionSelection.fulfillment";
import AccountSelection from "./AccountSelection";
import AccountSelectionFulfillment from "./AccountSelection.fulfillment";
import Recommendation from "./Recommendation";
import RecommendationFulfillment from "./Recommendation.fulfillment";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator";
import api from "../../../../../helpers/api";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import i18n from "../../../../../helpers/i18n";

export default class ActionSelection extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState, isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	async componentDidMount()
	{
		console.log("Checking status of onboarding... ");
		let onboardingInfo = await _hasClientOnboarded.call(this, this.props.customer.ssnOrg);

		// Set navigation events
		let newCustomerState = {...this.state.customerState};

		if (onboardingInfo && !onboardingInfo.has_onboarded)
		{
			newCustomerState.checkbox_advice_onboarding = "yes";
		}

		this.setState({isLoading: false, onboardingInfo: onboardingInfo, customerState: newCustomerState}, () =>
		{
			this.props.setAppState({
				viewFulfilled: this.isFulfilled(),
				showValidationWarning: false,
				navigationButtons: {
					sharing: {},
					back: {},
					next: {
						onClick: () =>
						{
							if (this.state.isFulfilled === true)
							{
								return;
							}

							this.setState({hasFormValidationErrors: true});
							return this.props.setAppState({
								showValidationWarning: true
							});
						}
					}
				}
			});
		});
	}

	render()
	{
		const {hasFormValidationErrors, readOnly} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		if (this.state.isLoading)
		{
			return (<div className={classes}>
					<ContentBox>
						<ActivityIndicator color="#4cb7d5" busy/>
					</ContentBox>
				</div>);
		}

		return (<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("account_selection", "type_of_consultation")}</h2>

					<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
						<div style={{alignSelf: "flex-start"}}>
							<Checkbox
								label={this.state.onboardingInfo.has_onboarded ? "Dokumentation" : "Uppdatering dokumentation"}
								name={"checkbox_advice_basicinfo"}
								checked={true}
								value={"yes"}
                readOnly={readOnly}
							/>
						</div>
						<div style={{alignSelf: "flex-start"}}>
							<input type={"hidden"} name={"client_has_onboarded"}
								   value={this.state.onboardingInfo.has_onboarded ? "yes" : "no"}/>

							<Checkbox
								label="Kontoöppning"
								name={"checkbox_advice_onboarding"}
								checked={!this.state.onboardingInfo.has_onboarded || this.state.workflow === "futur" || this.state.customerState.checkbox_advice_onboarding === "yes"}
								value={"yes"}
                readOnly={readOnly}
								onChange={(checked) =>
								{
									this.setCustomerState({checkbox_advice_onboarding: (!this.state.onboardingInfo.has_onboarded || this.state.workflow === "futur" || checked) ? "yes" : "no"});
								}}
							/>

							{this.state.workflow !== "futur" && !this.state.onboardingInfo.has_onboarded && (
								<React.Fragment>
									<strong style={{margin: "10px 0 20px 0"}}>
										Obligatoriskt då kunden inte har onboardats förut.
									</strong>
								</React.Fragment>)}
						</div>
						{this.state.workflow !== "futur" && (<div style={{alignSelf: "flex-start"}}>
								<Checkbox
									label="Rekommendation"
									name={"checkbox_advice_recommendation"}
									checked={this.state.customerState.checkbox_advice_recommendation === "yes"}
									value={"yes"}
                  readOnly={readOnly}
									onChange={(checked) =>
									{
										this.setCustomerState({checkbox_advice_recommendation: checked ? "yes" : "no"});
									}}
								/>
							</div>)}
					</StackLayout>

					{(!this.state.onboardingInfo.has_onboarded || this.state.customerState.checkbox_advice_onboarding === "yes") && (
						<React.Fragment>
							<div className="h-line spacing" style={{marginTop: "25px"}}/>
							<AccountSelection customer={this.props.customer}
											  onUpdated={this._accountSelectionFulfilled}
											  onboardingInfo={this.state.onboardingInfo}
											  currentWorkflow={this.state.workflow}
											  highlightErrors={hasFormValidationErrors}
                        readOnly={readOnly}
              />
							<input type={"hidden"} name={"client_has_onboarded"} value={"yes"}/>
						</React.Fragment>)}

					{this.state.customerState.checkbox_advice_recommendation === "yes" && (<React.Fragment>
							<div className="h-line spacing" style={{marginTop: "25px"}}/>

							<Recommendation customer={this.props.customer}
											user={this.props.user}
											onUpdated={this._recommendationFulfilled}
											onboardingInfo={this.state.onboardingInfo}
											hasNewAccountAf={this.state.customerState.checkbox_advice_onboarding === "yes" && this.state.customerState.newAccountAf}
											hasNewAccountIsk={this.state.customerState.checkbox_advice_onboarding === "yes" && this.state.customerState.newAccountIsk}
											hasNewAccountKf={this.state.customerState.checkbox_advice_onboarding === "yes" && this.state.customerState.newAccountKf}
											hasNewAccountTp={this.state.customerState.checkbox_advice_onboarding === "yes" && this.state.customerState.newAccountTp}
											currentWorkflow={this.state.workflow}
											highlightErrors={hasFormValidationErrors}
                      readOnly={readOnly}
              />
						</React.Fragment>)}
				</ContentBox>
			</div>);
	}

	_accountSelectionFulfilled = (fulfilled, newAccountAf, newAccountIsk, newAccountKf, newAccountTp) =>
	{
		const newState = this.state;
		newState.isAccountSelectionFulfilled = fulfilled;
		newState.customerState.newAccountAf = newAccountAf;
		newState.customerState.newAccountIsk = newAccountIsk;
		newState.customerState.newAccountKf = newAccountKf;
		newState.customerState.newAccountTp = newAccountTp;
		this.setCustomerState({newAccountAf, newAccountIsk, newAccountKf, newAccountTp}, () =>
		{
			this._modulesFulfilled(newState);
		});
	};

	_recommendationFulfilled = (fulfilled) =>
	{
		const newState = this.state;
		newState.isPlacementRecommendationFulfilled = fulfilled;
		this._modulesFulfilled(newState);
	};

	_modulesFulfilled(newState)
	{
		newState.isFulfilled = this.isFulfilled(newState);
		this.setState(newState, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}
}

function _hasClientOnboarded(customerSsn)
{
	return new Promise(async (resolve, reject) =>
	{
		try
		{
			const result = await api("/citroneer/onboarding", {"ssn": customerSsn});
			resolve(result);
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, currentWorkflow, viewNo, hasFormValidationErrors, readOnly} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer, currentWorkflow);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
  newState.readOnly = readOnly;
	return newState;
}


export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		workflow: workflow,
		isFulfilled: false,
		isLoading: true,

		onboardingInfo: {
			has_onboarded: (customer.getData("client_has_onboarded") ? customer.getData("client_has_onboarded") === "yes" : false),
			has_naked_account: false,
			active_portfolios: []
		},
		isAccountSelectionFulfilled: AccountSelectionFulfillment.call(this, AccountSelection.getViewInitialStateForCustomer(customer), null),
		isPlacementRecommendationFulfilled: false,
		customerState: {
			checkbox_advice_onboarding: customer.getData("checkbox_advice_onboarding"),
			checkbox_advice_recommendation: customer.getData("checkbox_advice_recommendation"),

			newAccountAf: false,
			newAccountIsk: false,
			newAccountKf: false,
			newAccountTp: false,
		}
	};

	initialState.isPlacementRecommendationFulfilled = RecommendationFulfillment.call(this, Recommendation.getViewInitialStateForCustomer(customer, initialState.onboardingInfo, false, false, false, false), null);

	if (!initialState.onboardingInfo.has_onboarded || workflow === "futur")
	{
		initialState.customerState.checkbox_advice_onboarding = "yes";
	}

	return initialState;
}

function _getFields()
{
	let fields = ["client_has_onboarded", "checkbox_advice_onboarding", "checkbox_advice_recommendation"];
	fields = fields.concat(AccountSelection.getFields());
	fields = fields.concat(Recommendation.getFields());
	return fields;
}