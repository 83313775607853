import "typeface-roboto";
import "typeface-roboto-slab";

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    "@media (min-width:0px)": {
      fontSize: pxToRem(xs)
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm)
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md)
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_PRIMARY = "Roboto";
const FONT_SECONDARY = "Lato";

// TODO: fix responsiveFontSizes - needs all args
const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h3: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 800,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 20, sm: 20, md: 24, lg: 26 })
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 800,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs: 18, md: 18, lg: 18 })
  },
  h7: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 800,
    lineHeight: 1,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 15, lg: 16 })
  },
  menuDrawer: {
    // See Typography override for default color style
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: "initial",
    fontSize: "14px",
  },
  // TODO: do we need this?
  notesDrawer: {
    // See Typography override for default color style
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: "initial",
    fontSize: "14px",
  },
  tableCellHeader: {
    // See Typography override for default color style
    fontFamily: FONT_PRIMARY,
    fontWeight: 800,
    lineHeight: 1,
    fontSize: pxToRem(13.8),
    ...responsiveFontSizes({ xs: 13.8, lg: 13.8 })
  },
  tableCell: {
    // See Typography override for default color style
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: 1,
    fontSize: pxToRem(13.8),
    ...responsiveFontSizes({ xs: 13.8, lg: 13.8 })
  },
  modalCell: {
    fontFamily: FONT_PRIMARY,
    lineHeight: 20 / 12,
    fontSize: pxToRem(14)
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 14, md: 16 })
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  subtitle_form: {
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    fontFamily: FONT_PRIMARY,
    lineHeight: pxToRem(22),
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 14, sm: 14, lg: 14 })
  },
  body3: {
    fontFamily: FONT_PRIMARY,
    lineHeight: 20 / 12,
    fontSize: pxToRem(12)
  },
  body4: {
    fontFamily: FONT_PRIMARY,
    lineHeight: 20 / 12,
    fontSize: "10px",
    display: "block"
  },
  listItemStrong: {
    fontFamily: FONT_PRIMARY,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: "bold",
    ...responsiveFontSizes({ xs: 14, sm: 14, lg: 14 })
  },
  listItemSub: {
    fontFamily: FONT_PRIMARY,
    lineHeight: pxToRem(16),
    fontSize: pxToRem(12)
  },
  caption: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 800,
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: "uppercase"
  },
  button: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "none"
  },
  logo: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: "22px",
    color: "#333",
    textTransform: "none"
  },
  sectionHeader: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 600,
    lineHeight: 1,
    fontSize: "20px",
    color: "#333",
    textTransform: "none"
  }
};

export default typography;
