import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo
} from "react";
import PropTypes from "prop-types";

const NotesDrawerCtxt = createContext(null);

const NotesDrawerProvider = ({ children }) => {
  const [state, setState] = useState({ open: false });

  // toggle drawer, memoized
  const toggleDrawer = useCallback(() => {
    setState(prevState => ({
      open: !prevState.open
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      toggleDrawer
    }),
    [state, toggleDrawer]
  );

  return (
    <NotesDrawerCtxt.Provider value={contextValue}>
      {children}
    </NotesDrawerCtxt.Provider>
  );
};

export const NotesDrawerConsumer = () => {
  return useContext(NotesDrawerCtxt);
};

export default NotesDrawerProvider;

NotesDrawerProvider.propTypes = {
  children: PropTypes.node
};
