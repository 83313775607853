import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextField from "@components/TextField/TextField";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./MoneyLaunderingQuestions.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import Modal from "../../../../../components/Modal/Modal";
import Select from "../../../../../components/Select/Select";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import NumberField from "../../../components/NumberField/NumberField.jsx";

export default class MoneyLaunderingQuestions extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static employmentOptions = [
		{label: "Anställd", value: "Anställd"},
		{label: "Egen företagare", value: "Egen företagare"},
		{label: "Pensionär", value: "Pensionär"},
		{label: "Studerande", value: "Studerande"},
		{label: "Annat", value: "Annat"}
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState, hasFormValidationErrors, readOnly} = state;
		const {onRestartSession, customer} = this.props;
		const classes = createClassName("Cob-view Cob-view3", {});

		let employmentText = customerState.radio_kyc_form_employment;
		if (employmentText !== "Studerande" && employmentText !== "Pensionär")
		{
			employmentText += ": " + customerState.radio_kyc_employment_text;
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<Modal
					visible={customerState.checkbox_aml_own_money != null && customerState.checkbox_aml_own_money !== "" && customerState.checkbox_aml_own_money !== "yes"}
					status="danger" title="Kund måste äga pengar eller värdepapper">
					<React.Fragment>
						<p>Som kund måste du äga pengarna och/eller de värdepapper du vill spara eller investera i.</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  checkbox_aml_own_money: null
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>{i18n("cob", "questions_according_to_anti_money_laundering_act")}</h2>
					<div className="Cob-view3-fields">
						{!customer.isCompany && (
							<React.Fragment>
								<h4 style={{width: "100%", padding: "0"}}>
									Vad är kundens huvudsakliga sysselsättning?
								</h4>
								<Select
									label="Ange"
									name="radio_kyc_form_employment"
									selectedIndex={MoneyLaunderingQuestions.employmentOptions.map(c => c.value).indexOf(customerState.radio_kyc_form_employment)}
									options={MoneyLaunderingQuestions.employmentOptions}
									onChange={(option) => this.setCustomerState({
										radio_kyc_form_employment: option.value
									})}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								{customerState.radio_kyc_form_employment !== "" &&
									customerState.radio_kyc_form_employment !== "Studerande" &&
									customerState.radio_kyc_form_employment !== "Pensionär" && (
									<React.Fragment>
										<p className="Cob-description" style={{marginTop: "0", marginBottom: "0"}}>
											{customerState.radio_kyc_form_employment !== "Annat" && (
												<React.Fragment>
													Ange namn på arbetsgivare/bolagsnamn och arbetsroll.
												</React.Fragment>
											)}
											{customerState.radio_kyc_form_employment === "Annat" && (
												<React.Fragment>
													Beskriv kundens situation.
												</React.Fragment>
											)}
										</p>
										<TextView
											name="radio_kyc_employment_text"
											label={`${i18n("general", "notes")} (${400 - customerState.radio_kyc_employment_text.length} tecken kvar)`}
											value={customerState.radio_kyc_employment_text}
											maxLength={500}
											validation={value => value.length > 0}
											resize="vertical"
											onChange={({target}) => this.setCustomerState({radio_kyc_employment_text: target.value})}
											fullWidth
											minHeight={75}
											highlightErrors={hasFormValidationErrors}
                      readOnly={readOnly}
											style={{marginTop: "10px"}}
										/>
									</React.Fragment>
								)}

								<input type={"hidden"} name={"customer_occupation"} value={employmentText} />

								<h4 style={{marginTop: "40px"}}>
									Inom vilken bransch är eller har kunden varit verksam under de senaste 24 månaderna?
								</h4>
								<TextView
									name="customer_occupation_last24m"
									label={`${i18n("general", "notes")} (${600 - customerState.customer_occupation_last24m.length} tecken kvar)`}
									value={customerState.customer_occupation_last24m}
									maxLength={600}
									validation={value => value.length > 0}
									resize="vertical"
									onChange={({target}) => this.setCustomerState({customer_occupation_last24m: target.value})}
									fullWidth
									minHeight={150}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginTop: "40px"}}>Äger kunden sina pengar och/eller värdepapper?</h4>
								<OptionsList
									type="radio"
									name="checkbox_aml_own_money"
									description={"Som kund måste du äga pengarna och/eller de värdepapper du vill spara eller investera i. Bekräfta genom att välja Ja nedan."}
									value={customerState.checkbox_aml_own_money}
									options={[
										{
											label: i18n("general", "no"),
											value: "no",
											checked: customerState.checkbox_aml_own_money === "no"
										},
										{
											label: i18n("general", "yes"),
											value: "yes",
											checked: customerState.checkbox_aml_own_money === "yes"
										}
									]}
									onChange={(checked, checkbox_aml_own_money) => this.setCustomerState({checkbox_aml_own_money})}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Varifrån kommer kundens ekonomiska medel inom ramen för denna investering?
								</h4>
								<OptionsList
									type="checkbox"
									description="Du kan välja flera alternativ."
									orientation="vertical"
									value={
										customerState.checkbox_aml_origin_salary_pension === "yes" ||
										customerState.checkbox_aml_origin_capital === "yes" ||
										customerState.checkbox_advice_origin_business === "yes" ||
										customerState.checkbox_advice_origin_companyexit === "yes" ||
										customerState.checkbox_aml_origin_savings_herit_gift === "yes" ||
										customerState.checkbox_aml_origin_other === "yes" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_aml_origin_salary_pension",
											label: "Inkomst av tjänst/pension",
											value: customerState.checkbox_aml_origin_salary_pension === "yes" ? "yes" : "no",
											checked: customerState.checkbox_aml_origin_salary_pension === "yes",
											onChange: checked => this.setCustomerState({checkbox_aml_origin_salary_pension: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_aml_origin_capital",
											label: "Inkomst av kapital",
											value: customerState.checkbox_aml_origin_capital === "yes" ? "yes" : "no",
											checked: customerState.checkbox_aml_origin_capital === "yes",
											onChange: checked => this.setCustomerState({checkbox_aml_origin_capital: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_advice_origin_business",
											label: "Inkomst från rörelse/näringsverksamhet",
											value: customerState.checkbox_advice_origin_business === "yes" ? "yes" : "no",
											checked: customerState.checkbox_advice_origin_business === "yes",
											onChange: checked => this.setCustomerState({checkbox_advice_origin_business: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_advice_origin_companyexit",
											label: "Företags- eller fastighetsförsäljning",
											value: customerState.checkbox_advice_origin_companyexit === "yes" ? "yes" : "no",
											checked: customerState.checkbox_advice_origin_companyexit === "yes",
											onChange: checked => this.setCustomerState({checkbox_advice_origin_companyexit: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_aml_origin_savings_herit_gift",
											label: "Arv/gåva",
											value: customerState.checkbox_aml_origin_savings_herit_gift === "yes" ? "yes" : "no",
											checked: customerState.checkbox_aml_origin_savings_herit_gift === "yes",
											onChange: checked => this.setCustomerState({checkbox_aml_origin_savings_herit_gift: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_aml_origin_other",
											label: "Annat (beskriv)",
											value: customerState.checkbox_aml_origin_other === "yes" ? "yes" : "no",
											checked: customerState.checkbox_aml_origin_other === "yes",
											onChange: checked => this.setCustomerState({checkbox_aml_origin_other: checked ? "yes" : "no"})
										}
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								{customerState.checkbox_aml_origin_other === "yes" && (
									<TextField
										style={{marginTop: "-20px", marginLeft: "11px"}}
										name="aml_origin_other"
										label={"Vänligen beskriv"}
										value={customerState.aml_origin_other}
										validation={this._textValidation}
										onChange={({target}) => this.setCustomerState({aml_origin_other: target.value})}
										highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
									/>
								)}
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>
								<h4 style={{marginBottom: "0"}}>Vilken bolagsform har företaget?</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="radio_company_form"
									value={customerState.radio_company_form}
									options={[
										{
											label: "Aktiebolag",
											value: "ab",
											checked: customerState.radio_company_form === "ab"
										},
										{
											label: "Handelsbolag/Kommanditbolag",
											value: "hbkb",
											checked: customerState.radio_company_form === "hbkb"
										},
										{
											label: "Stat/Kommun/Landsting",
											value: "statkom",
											checked: customerState.radio_company_form === "statkom"
										},
										{
											label: "Stiftelse",
											value: "stiftelse",
											checked: customerState.radio_company_form === "stiftelse"
										},
										{
											label: "Förening",
											value: "forening",
											checked: customerState.radio_company_form === "forening"
										},
										{
											label: "Förbund",
											value: "forbund",
											checked: customerState.radio_company_form === "forbund"
										},
										{
											label: "Börsbolag i Sverige",
											value: "borsbolag",
											checked: customerState.radio_company_form === "borsbolag"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_form: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									I vilken bransch verkar företaget?
								</h4>
								<TextField
									name="company_business"
									value={customerState.company_business}
									label="Ange"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({company_business: target.value})}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Är företaget ett finansiellt institut?
								</h4>
								<OptionsList
									type="radio"
									orientation="horizontal"
									name="radio_company_fininst"
									value={customerState.radio_company_fininst}
									options={[
										{
											label: "Nej",
											value: "Nej",
											checked: customerState.radio_company_fininst === "Nej"
										},
										{
											label: "Ja",
											value: "Ja",
											checked: customerState.radio_company_fininst === "Ja"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_fininst: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Är företaget aktivt* eller passivt?
								</h4>
								<OptionsList
									type="radio"
									orientation="horizontal"
									name="radio_company_active"
									value={customerState.radio_company_active}
									options={[
										{
											label: "Aktivt",
											value: "Ja",
											checked: customerState.radio_company_active === "Ja"
										},
										{
											label: "Passivt",
											value: "Nej",
											checked: customerState.radio_company_active === "Nej"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_active: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
								<p style={{fontStyle: "italic", maxWidth: "600px", marginTop: "-5px"}}>
									*Ett företag definieras som aktivt om minst 50% av företagets intäkter kommer från
									rörelse, till exempel
									försäljning av varor och tjänster. Andra exempel på aktiva företag är: 1.
									Uppstarstsbolag (de första två åren)
									2. Stiftelser och ideella föreningar som inte är skattskyldiga.
								</p>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Hur många anställda har företaget?
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="radio_company_employees"
									value={customerState.radio_company_employees}
									options={[
										{
											label: "0",
											value: "0",
											checked: customerState.radio_company_employees === "0"
										},
										{
											label: "1-9",
											value: "1-9",
											checked: customerState.radio_company_employees === "1-9"
										},
										{
											label: "10-50",
											value: "10-50",
											checked: customerState.radio_company_employees === "10-50"
										},
										{
											label: "51-99",
											value: "51-99",
											checked: customerState.radio_company_employees === "51-99"
										},
										{
											label: "Över 100",
											value: ">100",
											checked: customerState.radio_company_employees === ">100"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_employees: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>Hur stor är nettoomsättningen?</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="radio_company_turnover"
									value={customerState.radio_company_turnover}
									options={[
										{
											label: "Nystartad verksamhet",
											value: "new",
											checked: customerState.radio_company_turnover === "new"
										},
										{
											label: "Mindre än 3 miljoner",
											value: "<3",
											checked: customerState.radio_company_turnover === "<3"
										},
										{
											label: "Mellan 3 och 40 miljoner",
											value: "3-40",
											checked: customerState.radio_company_turnover === "3-40"
										},
										{
											label: "Mer än 40 miljoner",
											value: ">40",
											checked: customerState.radio_company_turnover === ">40"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_turnover: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Varifrån kommer kundens ekonomiska medel inom ramen för denna investering?
								</h4>
								<OptionsList
									type="checkbox"
									description="Du kan välja flera alternativ."
									orientation="vertical"
									value={
										customerState.checkbox_company_funds_profit === "Ja" ||
										customerState.checkbox_company_funds_compsales === "Ja" ||
										customerState.checkbox_company_funds_aktiekapital === "Ja" ||
										customerState.checkbox_company_funds_invest === "Ja" ||
										customerState.checkbox_company_funds_membership === "Ja" ||
										customerState.checkbox_company_funds_donation === "Ja" ||
										customerState.checkbox_company_funds_grants === "Ja" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_company_funds_profit",
											label: "Vinst från den egna verksamheten",
											value: customerState.checkbox_company_funds_profit === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_profit === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_profit: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_compsales",
											label: "Företagsförsäljning",
											value: customerState.checkbox_company_funds_compsales === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_compsales === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_compsales: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_aktiekapital",
											label: "Aktiekapital",
											value: customerState.checkbox_company_funds_aktiekapital === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_aktiekapital === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_aktiekapital: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_invest",
											label: "Tidigare placeringar/investeringar",
											value: customerState.checkbox_company_funds_invest === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_invest === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_invest: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_membership",
											label: "Medlemsavgifter",
											value: customerState.checkbox_company_funds_membership === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_membership === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_membership: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_donation",
											label: "Donationer",
											value: customerState.checkbox_company_funds_donation === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_donation === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_donation: checked ? "Ja" : "no"})
										},
										{
											name: "checkbox_company_funds_grants",
											label: "Anslag",
											value: customerState.checkbox_company_funds_grants === "Ja" ? "Ja" : "no",
											checked: customerState.checkbox_company_funds_grants === "Ja",
											onChange: checked => this.setCustomerState({checkbox_company_funds_grants: checked ? "Ja" : "no"})
										},
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>
								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Varifrån kommer kundens överföringar/insättningar?
								</h4>
								<OptionsList
									type="checkbox"
									description={"Du kan välja flera alternativ."}
									orientation="vertical"
									value={
										customerState.swedishBank === "yes" ||
										customerState.foreignBank === "yes" ||
										customerState.swedishInsurance === "yes" ||
										customerState.foreignInsurance === "yes" ||
										customerState.sourcePension === "yes" ||
										customerState.sourceEmployer === "yes" ||
										customerState.checkbox_customer_kyc_deposit_other === "yes" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_customer_kyc_deposit_swedish_bank",
											label: "Svensk bank/institut",
											value: customerState.swedishBank === "yes" ? "yes" : "no",
											checked: customerState.swedishBank === "yes",
											onChange: checked => this.setCustomerState({swedishBank: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_swedish_insurance",
											label: "Svenskt försäkringsföretag",
											value: customerState.swedishInsurance === "yes" ? "yes" : "no",
											checked: customerState.swedishInsurance === "yes",
											onChange: checked => this.setCustomerState({swedishInsurance: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_pension",
											label: "Pension",
											value: customerState.sourcePension === "yes" ? "yes" : "no",
											checked: customerState.sourcePension === "yes",
											onChange: checked => this.setCustomerState({sourcePension: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_foreign_bank",
											label: "Utländsk bank/institut",
											value: customerState.foreignBank === "yes" ? "yes" : "no",
											checked: customerState.foreignBank === "yes",
											onChange: checked => this.setCustomerState({foreignBank: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_foreign_insurance",
											label: "Utländskt försäkringsföretag",
											value: customerState.foreignInsurance === "yes" ? "yes" : "no",
											checked: customerState.foreignInsurance === "yes",
											onChange: checked => this.setCustomerState({foreignInsurance: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_employer",
											label: "Arbetsgivare",
											value: customerState.sourceEmployer === "yes" ? "yes" : "no",
											checked: customerState.sourceEmployer === "yes",
											onChange: checked => this.setCustomerState({sourceEmployer: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_other",
											label: "Annat (beskriv)",
											value: customerState.checkbox_customer_kyc_deposit_other === "yes" ? "yes" : "no",
											checked: customerState.checkbox_customer_kyc_deposit_other === "yes",
											onChange: checked => this.setCustomerState({checkbox_customer_kyc_deposit_other: checked ? "yes" : "no"})
										}
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								{customerState.checkbox_customer_kyc_deposit_other === "yes" && (
									<TextField
										name="customer_kyc_deposit_other"
										value={customerState.customer_kyc_deposit_other}
										style={{marginTop: "-20px", marginLeft: "11px"}}
										label="Ange"
										validation={value => value.length > 0}
										onChange={({target}) => this.setCustomerState({customer_kyc_deposit_other: target.value})}
										highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
									/>
								)}
							</React.Fragment>
						)}

						{!customer.isCompany && (
							<React.Fragment>
								<h4 style={{marginBottom: "0", marginTop: "40px"}}>
									Varifrån kommer kundens överföringar/insättningar?
								</h4>
								<OptionsList
									type="checkbox"
									description={"Du kan välja flera alternativ."}
									orientation="vertical"
									value={
										customerState.swedishBank === "yes" ||
										customerState.swedishInsurance === "yes" ||
										customerState.sourcePension === "yes" ||
										customerState.checkbox_customer_kyc_deposit_other === "yes" ||
										customerState.foreignBank === "yes" ||
										customerState.foreignInsurance === "yes" ||
										customerState.sourceEmployer === "yes" ?
											"filled" :
											""
									}
									options={[
										{
											name: "checkbox_customer_kyc_deposit_swedish_bank",
											label: "Svensk bank/institut",
											value: customerState.swedishBank === "yes" ? "yes" : "no",
											checked: customerState.swedishBank === "yes",
											onChange: checked => this.setCustomerState({swedishBank: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_swedish_insurance",
											label: "Svenskt försäkringsföretag",
											value: customerState.swedishInsurance === "yes" ? "yes" : "no",
											checked: customerState.swedishInsurance === "yes",
											onChange: checked => this.setCustomerState({swedishInsurance: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_pension",
											label: "Pension",
											value: customerState.sourcePension === "yes" ? "yes" : "no",
											checked: customerState.sourcePension === "yes",
											onChange: checked => this.setCustomerState({sourcePension: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_foreign_bank",
											label: "Utländsk bank/institut",
											value: customerState.foreignBank === "yes" ? "yes" : "no",
											checked: customerState.foreignBank === "yes",
											onChange: checked => this.setCustomerState({foreignBank: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_foreign_insurance",
											label: "Utländskt försäkringsföretag",
											value: customerState.foreignInsurance === "yes" ? "yes" : "no",
											checked: customerState.foreignInsurance === "yes",
											onChange: checked => this.setCustomerState({foreignInsurance: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_employer",
											label: "Arbetsgivare",
											value: customerState.sourceEmployer === "yes" ? "yes" : "no",
											checked: customerState.sourceEmployer === "yes",
											onChange: checked => this.setCustomerState({sourceEmployer: checked ? "yes" : "no"})
										},
										{
											name: "checkbox_customer_kyc_deposit_other",
											label: "Annat (beskriv)",
											value: customerState.checkbox_customer_kyc_deposit_other === "yes" ? "yes" : "no",
											checked: customerState.checkbox_customer_kyc_deposit_other === "yes",
											onChange: checked => this.setCustomerState({checkbox_customer_kyc_deposit_other: checked ? "yes" : "no"})
										}
									]}
									validation={value => (value && value === "filled") || false}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								{customerState.checkbox_customer_kyc_deposit_other === "yes" && (
									<TextField
										name="customer_kyc_deposit_other"
										value={customerState.customer_kyc_deposit_other}
										style={{marginTop: "-20px", marginLeft: "11px"}}
										label="Ange"
										validation={value => value.length > 0}
										onChange={({target}) => this.setCustomerState({customer_kyc_deposit_other: target.value})}
										highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
									/>
								)}
							</React.Fragment>
						)}

						<h4 style={{marginBottom: "10px", marginTop: "40px"}}>
							Hur stor kommer kundens investering att vara vid det här tillfället?
						</h4>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{paddingBottom: "0", paddingTop: "20px"}}>
								<NumberField
									name="aml_first_deposit"
									label="Ange"
									unit=" SEK"
									value={customerState.aml_first_deposit || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({aml_first_deposit: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>
						<OptionsList
							type="radio"
							name="radio_deposit_type"
							orientation="horizontal"
							value={customerState.radio_deposit_type}
							description={"Typ av investering"}
							options={[
								{
									label: "Insättning",
									value: "deposit",
									checked: customerState.radio_deposit_type === "deposit"
								},
								{
									label: "Omallokering",
									value: "reallocation",
									checked: customerState.radio_deposit_type === "reallocation"
								}
							]}
							onChange={(checked, radio_deposit_type) => this.setCustomerState({radio_deposit_type})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
							style={{marginBottom: "0"}}
						/>

						<h4 style={{marginBottom: "0", marginTop: "40px"}}>
							Hur ofta planerar kunden att göra insättningar?
						</h4>
						<OptionsList
							type="radio"
							name="radio_aml_deposit"
							orientation="vertical"
							value={customerState.radio_aml_deposit}
							options={[
								{
									label: "Engångsinsättning",
									value: "Onetime",
									checked: customerState.radio_aml_deposit === "Onetime"
								},
								{
									label: "Månadsvis",
									value: "Monthly",
									checked: customerState.radio_aml_deposit === "Monthly"
								},
								{
									label: "Flera gånger årligen",
									value: "Yearly_several",
									checked: customerState.radio_aml_deposit === "Yearly_several"
								},
								{
									label: "En gång om året",
									value: "Yearly_once",
									checked: customerState.radio_aml_deposit === "Yearly_once"
								}
							]}
							onChange={(checked, radio_aml_deposit) => this.setCustomerState({radio_aml_deposit})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
							style={{paddingRight: "45px"}}
						/>

						<h4 style={{marginBottom: "0", marginTop: "40px"}}>
							Hur stora kommer kundens insättningar att vara per år?
						</h4>
						<OptionsList
							type="radio"
							name="radio_aml_deposit_amt"
							orientation="vertical"
							value={customerState.radio_aml_deposit_amt}
							options={[
								{
									label: "0 - 100 000 kr",
									value: "0-100",
									checked: customerState.radio_aml_deposit_amt === "0-100"
								},
								{
									label: "100 000 - 500 000 kr",
									value: "100-500",
									checked: customerState.radio_aml_deposit_amt === "100-500"
								},
								{
									label: "Mer än 500 000 kr",
									value: ">500",
									checked: customerState.radio_aml_deposit_amt === ">500"
								}
							]}
							onChange={(checked, radio_aml_deposit_amt) => this.setCustomerState({radio_aml_deposit_amt})}
							validation={this._optionsValidation}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>


						{customer.isCompany && (
							<React.Fragment>
								<h4 style={{marginBottom: "0", marginTop: "40px"}}>Har företaget en revisor?</h4>
								<OptionsList
									type="radio"
									orientation="horizontal"
									name="radio_company_revisor"
									value={customerState.radio_company_revisor}
									options={[
										{
											label: "Nej",
											value: "Nej",
											checked: customerState.radio_company_revisor === "Nej"
										},
										{
											label: "Ja",
											value: "Ja",
											checked: customerState.radio_company_revisor === "Ja"
										}
									]}
									onChange={(checked, option) =>
									{
										this.setCustomerState({radio_company_revisor: option});
									}}
									validation={this._optionsValidation}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</React.Fragment>
						)}

						<h4 style={{marginBottom: "0", marginTop: "40px"}}>
							Vad är syftet med affärsförbindelsen?
						</h4>
						<OptionsList
							type="checkbox"
							description="Du kan välja flera alternativ."
							orientation="vertical"
							value={
								customerState.checkbox_aml_purpose_longtime_savings === "yes" ||
								customerState.checkbox_aml_purpose_single === "yes" ||
								customerState.checkbox_aml_purpose_other === "yes" ?
									"filled" :
									""
							}
							options={[
								{
									name: "checkbox_aml_purpose_longtime_savings",
									label: "Långsiktigt sparande",
									value: customerState.checkbox_aml_purpose_longtime_savings === "yes" ? "yes" : "no",
									checked: customerState.checkbox_aml_purpose_longtime_savings === "yes",
									onChange: checked => this.setCustomerState({checkbox_aml_purpose_longtime_savings: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_purpose_single",
									label: "Enstaka värdepappersinvestering",
									value: customerState.checkbox_aml_purpose_single === "yes" ? "yes" : "no",
									checked: customerState.checkbox_aml_purpose_single === "yes",
									onChange: checked => this.setCustomerState({checkbox_aml_purpose_single: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_purpose_other",
									label: "Annat (beskriv)",
									value: customerState.checkbox_aml_purpose_other === "yes" ? "yes" : "no",
									checked: customerState.checkbox_aml_purpose_other === "yes",
									onChange: checked => this.setCustomerState({checkbox_aml_purpose_other: checked ? "yes" : "no"})
								}
							]}
							validation={value => (value && value === "filled") || false}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						{customerState.checkbox_aml_purpose_other === "yes" && (
							<TextField
								name="aml_purpose_other"
								value={customerState.aml_purpose_other}
								style={{marginTop: "-20px", marginLeft: "11px"}}
								label="Ange"
								validation={value => value.length > 0}
								onChange={({target}) => this.setCustomerState({aml_purpose_other: target.value})}
								highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						)}

						<div className="h-line spacing"/>

						<h3>Erfarenhet</h3>

						<div style={{width: "100%", display: "block", marginTop: "0"}}>
							<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
								<div style={{width: "100% important"}}>
									<p className="Cob-description">
										Hur många år har kunden handlat med finansiella instrument?
									</p>
									<StackLayout>
										<Select
											label="Ange"
											name="radio_kyc_yrsexp_aktier"
											selectedIndex={customerState.yearsExperienceIndex}
											options={[
												{label: "0-2 år", value: "0-2"},
												{label: "2-5 år", value: "2-5"},
												{label: "5-10 år", value: "5-10"},
												{label: ">10 år", value: ">10"}
											]}
											onChange={(option, yearsExperienceIndex) => this.setCustomerState({
												yearsExperience: option.value,
												yearsExperienceIndex
											})}
											highlightErrors={hasFormValidationErrors}
                      readOnly={readOnly}
										/>
									</StackLayout>

									<p className="Cob-description" style={{marginTop: "20px"}}>
										Vad har du för utbildning/utbildningsnivå?
									</p>
									<StackLayout style={{width: "300px"}}>
										<Select
											label="Ange"
											name="radio_kyc_edu"
											selectedIndex={customerState.highestEducationIndex}
											options={[
												{label: "Grundskola", value: "grund"},
												{label: "Gymnasium", value: "gym"},
												{label: "Högskola/Universitet", value: "uni"}
											]}
											onChange={(option, highestEducationIndex) => this.setCustomerState({
												radio_kyc_edu: option.value,
												highestEducationIndex
											})}
											highlightErrors={hasFormValidationErrors}
                      readOnly={readOnly}
										/>
									</StackLayout>

									{customerState.highestEducationIndex === 2 && (
										<StackLayout fullWidth orientation="vertical">
											<TextField
												style={{marginTop: "10px"}}
												name="customer_university"
												type="text"
												label="Högskola/universitet: ange inriktning"
												validation={value => value.length > 0}
												value={customerState.customer_university}
												onChange={({target}) => this.setCustomerState({customer_university: target.value})}
												highlightErrors={hasFormValidationErrors}
                        readOnly={readOnly}
											/>
										</StackLayout>
									)}
								</div>
							</StackLayout>

							<OptionsList
								type="radio"
								name="radio_customer_experience"
								description={"Har kunden tidigare investerat i finansiella instrument och är medveten om att värdet på investeringen kan både stiga och sjunka i värde?"}
								value={customerState.radio_customer_experience}
								options={[
									{
										label: i18n("general", "no"),
										value: "no",
										checked: customerState.radio_customer_experience === "no"
									},
									{
										label: i18n("general", "yes"),
										value: "yes",
										checked: customerState.radio_customer_experience === "yes"
									}
								]}
								onChange={(checked, radio_customer_experience) => this.setCustomerState({radio_customer_experience})}
								validation={this._optionsValidation}
								highlightErrors={hasFormValidationErrors}
                readOnly={readOnly}
							/>
						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors, readOnly} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
  newState.readOnly = readOnly;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		customerState: {
			isCompany: customer.isCompany,

			customer_occupation_last24m: customer.getData("customer_occupation_last24m") || "",
			customer_occupation: customer.getData("customer_occupation") || "",
			checkbox_aml_own_money: customer.getData("checkbox_aml_own_money", null),

			checkbox_aml_origin_salary_pension: customer.getData("checkbox_aml_origin_salary_pension", null),
			checkbox_aml_origin_capital: customer.getData("checkbox_aml_origin_capital", null),
			checkbox_advice_origin_business: customer.getData("checkbox_advice_origin_business", null),
			checkbox_advice_origin_companyexit: customer.getData("checkbox_advice_origin_companyexit", null),
			checkbox_aml_origin_savings_herit_gift: customer.getData("checkbox_aml_origin_savings_herit_gift", null),
			checkbox_aml_origin_other: customer.getData("checkbox_aml_origin_other", null),
			aml_origin_other: customer.getData("aml_origin_other", null),

			radio_aml_deposit_amt: customer.getData("radio_aml_deposit_amt", null),
			radio_aml_deposit: customer.getData("radio_aml_deposit", null),

			radio_kyc_form_employment: customer.getData("radio_kyc_form_employment", ""),
			radio_kyc_employment_text: customer.getData("radio_kyc_employment_text", ""),

			radio_company_revisor: customer.getData("radio_company_revisor", null),
			radio_company_form: customer.getData("radio_company_form", ""),
			company_business: customer.getData("company_business", ""),
			radio_company_fininst: customer.getData("radio_company_fininst", ""),
			radio_company_active: customer.getData("radio_company_active", ""),
			radio_company_employees: customer.getData("radio_company_employees", ""),
			radio_company_turnover: customer.getData("radio_company_turnover", ""),
			checkbox_customer_kyc_deposit_other: customer.getData("checkbox_customer_kyc_deposit_other", ""),
			customer_kyc_deposit_other: customer.getData("customer_kyc_deposit_other", ""),

			checkbox_company_funds_profit: customer.getData("checkbox_company_funds_profit", ""),
			checkbox_company_funds_compsales: customer.getData("checkbox_company_funds_compsales", ""),
			checkbox_company_funds_aktiekapital: customer.getData("checkbox_company_funds_aktiekapital", ""),
			checkbox_company_funds_invest: customer.getData("checkbox_company_funds_invest", ""),
			checkbox_company_funds_membership: customer.getData("checkbox_company_funds_membership", ""),
			checkbox_company_funds_donation: customer.getData("checkbox_company_funds_donation", ""),
			checkbox_company_funds_grants: customer.getData("checkbox_company_funds_grants", ""),

			checkbox_aml_purpose_single: customer.getData("checkbox_aml_purpose_single", null),
			checkbox_aml_purpose_longtime_savings: customer.getData("checkbox_aml_purpose_longtime_savings", null),
			checkbox_aml_purpose_other: customer.getData("checkbox_aml_purpose_other", null),
			aml_purpose_other: customer.getData("aml_purpose_other", ""),

			radio_deposit_type: customer.getData("radio_deposit_type", ""),

			swedishBank: customer.getData("checkbox_customer_kyc_deposit_swedish_bank", null),
			swedishInsurance: customer.getData("checkbox_customer_kyc_deposit_swedish_insurance", null),
			foreignBank: customer.getData("checkbox_customer_kyc_deposit_foreign_bank", null),
			foreignInsurance: customer.getData("checkbox_customer_kyc_deposit_foreign_insurance", null),
			sourcePension: customer.getData("checkbox_customer_kyc_deposit_pension", null),
			sourceEmployer: customer.getData("checkbox_customer_kyc_deposit_employer", null),

			radio_customer_experience: customer.getData("radio_customer_experience", ""),
			aml_first_deposit: customer.getData("aml_first_deposit", ""),

			yearsExperience: customer.getData("radio_kyc_yrsexp_aktier"),
			yearsExperienceIndex: -1,

			highestEducationIndex: (
				customer.getData("radio_kyc_edu") === "grund" ? 0 :
					customer.getData("radio_kyc_edu") === "gym" ? 1 :
						customer.getData("radio_kyc_edu") === "uni" ? 2 :
							-1
			),

			customer_university: customer.getData("customer_university") || "",
		},
		isFulfilled: false
	};

	if (customer.getData("radio_kyc_yrsexp_aktier") === "0-2")
	{
		initialState.customerState.yearsExperienceIndex = 0;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === "2-5")
	{
		initialState.customerState.yearsExperienceIndex = 1;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === "5-10")
	{
		initialState.customerState.yearsExperienceIndex = 2;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === ">10")
	{
		initialState.customerState.yearsExperienceIndex = 3;
	}

	/**
	 * Reset legacy values, these don't exist as alternatives any longer and should be treated as empty.
	 */
	if (initialState.customerState.radio_aml_deposit_amt === "100-250" ||
		initialState.customerState.radio_aml_deposit_amt === "250-500" ||
		initialState.customerState.radio_aml_deposit_amt === "500-1000" ||
		initialState.customerState.radio_aml_deposit_amt === ">1mn")
	{
		initialState.customerState.radio_aml_deposit_amt = "";
	}
	if (initialState.customerState.radio_aml_deposit === "<1/m" ||
		initialState.customerState.radio_aml_deposit === "1-5/m" ||
		initialState.customerState.radio_aml_deposit === ">5/m")
	{
		initialState.customerState.radio_aml_deposit = "";
	}

	return initialState;
}

function _getFields()
{

	let fieldsArray = [
		"customer_kyc_empl",
		"radio_kyc_yrsexp_aktier",
		"radio_kyc_edu",
		"customer_university",
		"radio_deposit_type",

		"checkbox_advice_origin_capital_return",
		"checkbox_aml_deposit_swe_bank",
		"checkbox_aml_deposit_swe_insurance",
		"aml_deposit_foreign_bank",
		"aml_deposit_foreign_insurance",
		"radio_customer_experience",

		"checkbox_aml_purpose_longtime_savings",
		"checkbox_aml_purpose_single",
		"aml_first_deposit",

		"radio_company_form",
		"company_business",
		"radio_company_fininst",
		"radio_company_active",
		"radio_company_turnover",
		"radio_company_employees",

		"radio_company_revisor",
		"checkbox_company_funds_profit",
		"checkbox_company_funds_aktiekapital",
		"checkbox_company_funds_compsales",
		"checkbox_company_funds_invest",
		"checkbox_company_funds_membership",
		"checkbox_company_funds_donation",
		"checkbox_company_funds_grants",

		// New fields (CN-85)
		"checkbox_customer_kyc_deposit_swedish_bank",
		"checkbox_customer_kyc_deposit_swedish_insurance",
		"checkbox_customer_kyc_deposit_foreign_bank",
		"checkbox_customer_kyc_deposit_foreign_insurance",
		"checkbox_customer_kyc_deposit_pension",
		"checkbox_customer_kyc_deposit_employer",
		"checkbox_customer_kyc_deposit_other",
		"customer_kyc_deposit_other",
		"checkbox_aml_purpose_other",
		"aml_purpose_other"
	];

	const customer = this.props.customer;
	if (!customer.isCompany)
	{
		fieldsArray.push("checkbox_aml_origin_savings");
		fieldsArray.push("checkbox_aml_origin_salary");
		fieldsArray.push("checkbox_aml_origin_capital_return");
		fieldsArray.push("checkbox_advice_origin_companyexit");
		fieldsArray.push("checkbox_aml_origin_other");
		fieldsArray.push("aml_origin_other");
		fieldsArray.push("checkbox_aml_origin_salary_pension");
		fieldsArray.push("checkbox_aml_origin_capital");
		fieldsArray.push("checkbox_advice_origin_business");
		fieldsArray.push("checkbox_aml_origin_savings_herit_gift");
	}

	return fieldsArray;
}