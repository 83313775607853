import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from '@mui/icons-material/Close';

import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuControlsContainer,
  // MenuDivider,
  // MenuSelectHeading,
  RichTextField,
  RichTextEditorProvider,
  MenuButtonUnderline // not part of StarterKit
} from "mui-tiptap";

import useNotesDrawer from "./useNotesDrawer";

const NotesDrawer = props => {
  const { open, drawerRef, editorRef, onToggle, customProps, editor, noteSaved, showNoteSaved } = useNotesDrawer(props);

  const content = () => (
    <Box
      className="CaseNotes"
      role="presentation"
    >
      <CloseIcon
        onClick={onToggle}
        sx={{
          position: "absolute",
          top: "15px",
          right: "15px",
          height: "32px",
          width: "32px",
          color: "#FFF",
          backgroundColor: "grey.400",
          borderRadius: "50%",
          padding: "5px",
          cursor: "pointer"
        }}
      />
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end"
        }}
      >
        <Typography variant="h6">Anteckningar</Typography>
        {noteSaved && (
          <Typography
            variant="body2"
            color="primary.main"
            sx={{
              ml: 2,
              mb: 0.2,
              color: "state.success",
              opacity: 0,
              animation: "fadeIn 0.5s ease-in forwards",
              "@keyframes fadeIn": {
                "0%": { opacity: 0 },
                "100%": { opacity: 1 }
              }
            }}
          >
            <b>Anteckningen sparad!</b>
          </Typography>
        )}
      </Box>
      <Box
        ref={editorRef}
      className="CaseNotes-editor"
      >
        <RichTextEditorProvider editor={editor} onBlur={showNoteSaved}>
          <RichTextField
            controls={
              <MenuControlsContainer>
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonStrikethrough />
                {/* Extensions not part of StarterKit */}
                <MenuButtonUnderline />
              </MenuControlsContainer>
            }
            onBlur={showNoteSaved}
          />
        </RichTextEditorProvider>
      </Box>
    </Box>
  );

  return (
    <SwipeableDrawer
      ref={drawerRef}
      PaperProps={{
        sx: {
          position: { md: "relative" },
          width: "100%",
          top: { xs: "75px", md: "60px" },
          overflow: "auto",
          borderRightColor: "gray.400",
          backgroundColor: "common.white",
          zIndex: 1001
        }
      }}
      variant="temporary"
      anchor="right"
      hideBackdrop
      open={open}
      onClose={onToggle}
      onOpen={onToggle}
      sx={{
        height: "100%",
        position: "fixed",
        right: 0,
        left: "unset",
        width: { xs: "100%", md: "30%", lg: "25%" },
        zIndex: 1001
      }}
      {...customProps}
    >
      {content()}
    </SwipeableDrawer>
  );
};

export default NotesDrawer;
