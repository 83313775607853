import React from "react";

// MUI Components
import Link from "@mui/material/Link";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// COB COMPONENTS
import Button from "@components/Button/Button";
import Icon from "@components/Icon/Icon";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import { createClassName } from "@helpers/utils";
import { md5 } from "../../helpers/utils";


export default class Modal extends React.PureComponent {
  
    constructor(props) {
      super(props);
      this.modalRef = React.createRef();
      
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    state = {
        visible: false,
    }

    handleClickOutside(event) {
      if (
          event.button === 0 &&
          this.props.onClose &&
          this.visible &&
          this.modalRef.current &&
          !this.modalRef.current.contains(event.target) &&
          (event.target.className.includes("Modal-overlay") ||
          event.target.className.includes("Modal-content-container"))
      ) {
          document.removeEventListener('mousedown', this.handleClickOutside);
          document.removeEventListener('keydown', this.handleKeyDown);
          this.props.onClose();
      }
    }
  
    handleKeyDown(event) {
      if (
        this.props.onClose &&
        this.visible &&
        event.key === 'Escape'
      ) {        
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
        this.props.onClose();
      }
    }

    visible(value) {
        this._visibleTimeout = setTimeout(() => {
            this.setState({ visible: value === true });
        });
    }

    componentDidMount() {
        const { visible } = this.props;

        if(visible === true) this.visible(true);

        if (visible) {
          document.addEventListener('mousedown', this.handleClickOutside);
          document.addEventListener('keydown', this.handleKeyDown);
        }
    }

    componentWillUnmount() {
        if(this._visibleTimeout) clearTimeout(this._visibleTimeout);        
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { visible } = this.props;

        if(prevProps.visible !== visible) {
            this.visible(visible === true);

            if (visible) {              
              document.addEventListener('mousedown', this.handleClickOutside);
              document.addEventListener('keydown', this.handleKeyDown);
            }
            else {
              document.removeEventListener('mousedown', this.handleClickOutside);
              document.removeEventListener('keydown', this.handleKeyDown);
            }
        }
    }

    render() {
        const { props, state } = this;
        const { view, children, direction, partner, title, subtitle, status, loggedOut } = props;

        const modalWrapperClasses = createClassName(props.className, {
            "Modal": true,
            "visible": state.visible,
            "start": view === 1,
            "horizontal": direction === "horizontal",
            "vertical": direction && direction !== "horizontal",
            [status]: !!status,
        });

        const modalContentClasses = createClassName(props.className, {
            "logged-in": !(loggedOut === true) || loggedOut === undefined,
        });

        return (
            <div className={ modalWrapperClasses }>
                <div className="Modal-overlay" />
                <div className={`Modal-content-container ${modalContentClasses}`}>
                    <div ref={this.modalRef}  className="Modal-content">
                        { !!title && (
                            <div className="Modal-title">
                                { !!status && (
                                    <Icon
                                        className="Modal-status-icon"
                                        size="large"
                                        value={
                                            status === "success" ? "check" :
                                            status === "warning" ? "warning" :
                                            status === "danger" ? "error" :
                                            null
                                        }
                                    />
                                )}
                                <h2>{ title }</h2>
                                {!!subtitle && (
                                  <h3
                                    className={"modal"}
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    {subtitle}
                                    {partner.name === 'fair' && (
                                      <Link
                                        variant="body"
                                        href={`https://kundportal.fairinvestments.se/kund/${md5(subtitle)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          display: "flex", alignItems: "center",
                                          textDecoration: "none",
                                          span: {
                                            textDecoration: "underline"
                                          },
                                          ml: 1.75,
                                          paddingTop: 0.125
                                        }}
                                      >
                                        <span
                                          style={{ display: "inline-block",
                                            fontSize: "11px", fontWeight: 700, marginRight: "2px" }}
                                        >
                                          Öppna i kundportal
                                        </span>
                                        <OpenInNewIcon fontSize="13px" />
                                      </Link>
                                    )}
                                  </h3>
                                )}
                            </div>
                        )}
                        { children }
                    </div>
                </div>
            </div>
        );
    }

    // Static components
    static Button(props) {
        return (
            <Button { ...props } appearance={ props.appearance || "medium" } size="small" />
        );
    }

    static ButtonsContainer({ justifyContent, children }) {
        return (
            <StackLayout className="Modal-buttons-container" justifyContent={justifyContent || "end"} fullWidth>{ children }</StackLayout>
        );
    }
}