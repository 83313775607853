import React from "react";
import OptionsList from "../OptionsList/OptionsList";
import Select from "../../../../components/Select/Select";
import i18n from "@helpers/i18n";

export default class PepQuestions extends React.PureComponent
{
	pepTypes = [
		{
			label: i18n("pep", "head_of_state"),
			value: "Statschefen"
		},
		{
			label: i18n("pep", "members_of_parliament"),
			value: "Riksdagsledamöter"
		},
		{
			label: i18n("pep", "prime_minister_and_other"),
			value: "Statsministern och övriga ministrar i regeringskansliet"
		},
		{
			label: i18n("pep", "judges"),
			value: "Domare i Högsta domstolen och Högsta förvaltningsdomstolen"
		},
		{
			label: i18n("pep", "auditors_general"),
			value: "Riksrevisorerna"
		},
		{
			label: i18n("pep", "board_swedish_central_bank"),
			value: "Riksbankens direktion"
		},
		{
			label: i18n("pep", "ambassadors"),
			value: "Ambassadörer"
		},
		{
			label: i18n("pep", "high_ranking_military_officers"),
			value: "Höga officerare (general, generallöjtnant, generalmajor, amiral, viceamiral och konteramiral)"
		},
		{
			label: i18n("pep", "ceo_board_member"),
			value: "Vd eller styrelseledamot i statsägda företag"
		},
		{
			label: i18n("pep", "person_in_leadership_position"),
			value: "Person i ledningen i en internationell organisation (styrelseledamöter, generalsekreterare med flera)"
		},
		{
			label: i18n("pep", "political_party_board_member"),
			value: "Ledamot i politiskt partis styrelse"
		}
	];

	state = {
		kyc_pep_self: this.props.customer.getData("kyc_pep_self") || "",
		kyc_pep_self_type: this.props.customer.getData("kyc_pep_self_type") || "",
		kyc_pep_relative: this.props.customer.getData("kyc_pep_relative") || "",
		kyc_pep_relative_type: this.props.customer.getData("kyc_pep_relative_type") || "",
	};

	render()
	{
		const {highlightErrors, valueInSwedish, readOnly} = this.props;

		let kycSelfSwedish = this.state.kyc_pep_self;
		if (this.state.kyc_pep_self === "yes")
		{
			kycSelfSwedish = "Ja";
		}
		else if (this.state.kyc_pep_self === "no")
		{
			kycSelfSwedish = "Nej";
		}

		let kycRelativeSwedish = this.state.kyc_pep_relative;
		if (this.state.kyc_pep_relative === "yes")
		{
			kycRelativeSwedish = "Ja";
		}
		else if (this.state.kyc_pep_relative === "no")
		{
			kycRelativeSwedish = "Nej";
		}

		return (
			<React.Fragment>
				<h3>{i18n("pep", "pep")}</h3>

				<p>
					{i18n("pep", "pep_description")}
				</p>

				<OptionsList
					style={{marginTop: "10px"}}
					type="radio"
					name="kyc_pep_self"
					value={this.state.kyc_pep_self}
					description={`${i18n("pep", "political_exposed")}?*`}
					options={[
						{
							label: i18n("general", "no"),
							value: "no",
							checked: this.state.kyc_pep_self === "no" || this.state.kyc_pep_self === "Nej"
						},
						{
							label: i18n("general", "yes"),
							value: "yes",
							checked: this.state.kyc_pep_self === "yes" || this.state.kyc_pep_self === "Ja"
						}
					]}
					onChange={(checked, kyc_pep_self) => this.setState({kyc_pep_self}, () => this._onChangeCallback())}
					validation={value => (value && value.length > 0) || false}
					highlightErrors={highlightErrors}/>
          readOnly={readOnly}

				{valueInSwedish && (
					<input type={"hidden"} name={"kyc_pep_self"} value={kycSelfSwedish}/>
				)}

				{(this.state.kyc_pep_self === "yes" || this.state.kyc_pep_self === "Ja") && (
					<React.Fragment>
						<Select
							name="kyc_pep_self_type"
							label={`${i18n("pep", "political_exposed_yes")}?*`}
							selectedIndex={this.pepTypes.map(c => c.value).indexOf(this.state.kyc_pep_self_type)}
							options={this.pepTypes}
							onChange={(option) =>
							{
								this.setState({kyc_pep_self_type: option.value}, () => this._onChangeCallback());
							}}
							highlightErrors={highlightErrors}
              readOnly={readOnly}
						/>
					</React.Fragment>
				)}

				{this.state.kyc_pep_self !== "yes" && this.state.kyc_pep_self !== "Ja" && (
					<input type={"hidden"} name={"kyc_pep_self_type"} value={""}/>
				)}

				<OptionsList
					style={{marginTop: "10px"}}
					type="radio"
					name="kyc_pep_relative"
					value={this.state.kyc_pep_relative}
					description={`${i18n("pep", "political_exposed_relatives")}?*`}
					options={[
						{
							label: i18n("general", "no"),
							value: "no",
							checked: this.state.kyc_pep_relative === "no" || this.state.kyc_pep_relative === "Nej"
						},
						{
							label: i18n("general", "yes"),
							value: "yes",
							checked: this.state.kyc_pep_relative === "yes" || this.state.kyc_pep_relative === "Ja"
						}
					]}
					validation={value => (value && value.length > 0) || false}
					onChange={(checked, kyc_pep_relative) => this.setState({kyc_pep_relative}, () => this._onChangeCallback())}
					highlightErrors={highlightErrors}
          readOnly={readOnly}
				/>

				{valueInSwedish && (
					<input type={"hidden"} name={"kyc_pep_relative"} value={kycRelativeSwedish}/>
				)}

				{(this.state.kyc_pep_relative === "yes" || this.state.kyc_pep_relative === "Ja") && (
					<React.Fragment>
						<Select
							name="kyc_pep_relative_type"
							label={`${i18n("pep", "political_exposed_relatives_yes")}?*`}
							selectedIndex={this.pepTypes.map(c => c.value).indexOf(this.state.kyc_pep_relative_type)}
							options={this.pepTypes}
							onChange={(option) =>
							{
								this.setState({kyc_pep_relative_type: option.value}, () => this._onChangeCallback());
							}}
							highlightErrors={highlightErrors}
              readOnly={readOnly}
						/>
					</React.Fragment>
				)}

				{this.state.kyc_pep_relative !== "yes" && this.state.kyc_pep_relative !== "Ja" && (
					<input type={"hidden"} name={"kyc_pep_relative_type"} value={""}/>
				)}
			</React.Fragment>
		);
	}

	_onChangeCallback = () =>
	{
		const {onUpdated} = this.props;

		if (!onUpdated)
		{
			return;
		}

		onUpdated(
			this.state.kyc_pep_self,
			this.state.kyc_pep_self_type,
			this.state.kyc_pep_relative,
			this.state.kyc_pep_relative_type
		);
	};
}