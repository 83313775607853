import React from "react";

// COMPONENTS
import TextField from "@components/TextField/TextField";

// HELPERS
import {localeNumber, parseLocaleNumber} from "@helpers/utils";


export default class NumberField extends React.PureComponent {
	render() {
		const {props} = this;
		let {name, unit = "", value, showSeparators, delimiter1, delimiter2, allowNegative, highlightErrors, readOnly} = props;

		if (allowNegative === undefined) {
			allowNegative = false;
		}

		if (!allowNegative && value === "-") {
			value = 0;
		}

		if ((value + "").indexOf("Infinity") > -1) {
			value = "0 SEK";
		}

		let valueForDisplay = _cleanValue.call(this, value, allowNegative);
		let fieldType = "number";
		if (showSeparators) {
			fieldType = "text";

			if (value) {
				if (allowNegative && value === "-") {
					valueForDisplay = "-";
				}
				else {
					valueForDisplay = localeNumber(valueForDisplay, 0);
					if (valueForDisplay.length >= 25) {
						valueForDisplay = valueForDisplay.substr(0, 25);
					}
				}
			}
		}

		let valueLocale = _localeValue.call(this, value, delimiter1, delimiter2);
		if (allowNegative && value === "-") {
			valueLocale = "-";
		}

		return (
			<div className="Cob-NumberField">
				<TextField
					className="Cob-NumberField-TextField"
					validation={this._validation}
					{...props}
					type={fieldType}
					value={valueForDisplay}
					unit={undefined}
          highlightErrors={highlightErrors}
          readOnly={readOnly}
				/>
				{!!unit && <div className="Cob-NumberField-unit">{unit.trim()}</div>}
				{name && <input type="hidden" name={name} value={valueLocale ? valueLocale + unit : ""}/>}
			</div>
		);
	}

	// Internal methods
	_validation = (value) => {
		if (!value) {
			return false;
		}

		let {allowNegative} = this.props;
		if (allowNegative === undefined) {
			allowNegative = false;
		}

		let valueForFormatting = _cleanValue.call(this, value, allowNegative);
		return !isNaN(valueForFormatting);
	};
}


// PRIVATE FUNCTIONS
function _cleanValue(value, allowNegative) {
	const {delimiter1, delimiter2} = this.props;
	if (typeof value === "number") return (Math.round(value * 10000) / 10000).toString();

	if (allowNegative && value === "-") {
		return value;
	}

	const result = value ? (!isNaN(value) ? value : parseLocaleNumber(value, delimiter1, delimiter2)) : "";
	return result;
}

function _localeValue(value) {

	if (value === "-") {
		return value;
	}

	const {decimals, delimiter1, delimiter2} = this.props;
	const result = value ? localeNumber(
		!isNaN(value) ? value : parseLocaleNumber(value, delimiter1, delimiter2),
		decimals, delimiter1, delimiter2
	) : "";
	return result;
}