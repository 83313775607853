import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import NumberField from "../../components/NumberField/NumberField";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import {localeNumber, createClassName} from "@helpers/utils";

// OTHER
import fulfillment from "./Assets.fulfillment";
import Select from "../../../../components/Select/Select";
import TextField from "../../../../components/TextField/TextField";
import OptionsList from "../../components/OptionsList/OptionsList";
import Checkbox from "../../../../components/Checkbox/Checkbox.jsx";


export default class Assets extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setTableData(fields)
	{
		this.setState(prevState => ({
			customerState: {
				...prevState.customerState,
				tableData: {...prevState.customerState.tableData, ...fields}
			}
		}));
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState, hasFormValidationErrors, readOnly} = state;
		const classes = createClassName("Cob-view Cob-view4", {});

		let totalAssets = 0;
		totalAssets += customerState.customer_asset_bank !== "" ? parseInt(customerState.customer_asset_bank.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_realestate !== "" ? parseInt(customerState.customer_asset_realestate.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_kinsurance_trad !== "" ? parseInt(customerState.customer_asset_kinsurance_trad.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_kinsurance_fund !== "" ? parseInt(customerState.customer_asset_kinsurance_fund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_kinsurance_account !== "" ? parseInt(customerState.customer_asset_kinsurance_account.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_pinsurance_trad !== "" ? parseInt(customerState.customer_asset_pinsurance_trad.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_pinsurance_fund !== "" ? parseInt(customerState.customer_asset_pinsurance_fund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_pinsurance_account !== "" ? parseInt(customerState.customer_asset_pinsurance_account.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_isk_strukt !== "" ? parseInt(customerState.customer_asset_isk_strukt.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_isk_stockfund !== "" ? parseInt(customerState.customer_asset_isk_stockfund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_account_strukt !== "" ? parseInt(customerState.customer_asset_account_strukt.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_account_stockfund !== "" ? parseInt(customerState.customer_asset_account_stockfund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAssets += customerState.customer_asset_other !== "" ? parseInt(customerState.customer_asset_other.replace(" SEK", "").split(' ').join(''), 10) : 0;

		let cashAssets = 0;
		cashAssets += customerState.customer_asset_bank !== "" ? parseInt(customerState.customer_asset_bank.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_kinsurance_fund !== "" ? parseInt(customerState.customer_asset_kinsurance_fund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_kinsurance_account !== "" ? parseInt(customerState.customer_asset_kinsurance_account.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_isk_strukt !== "" ? parseInt(customerState.customer_asset_isk_strukt.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_isk_stockfund !== "" ? parseInt(customerState.customer_asset_isk_stockfund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_account_strukt !== "" ? parseInt(customerState.customer_asset_account_strukt.replace(" SEK", "").split(' ').join(''), 10) : 0;
		cashAssets += customerState.customer_asset_account_stockfund !== "" ? parseInt(customerState.customer_asset_account_stockfund.replace(" SEK", "").split(' ').join(''), 10) : 0;

		let pensionAssets = 0;
		pensionAssets += customerState.customer_asset_pinsurance_trad !== "" ? parseInt(customerState.customer_asset_pinsurance_trad.replace(" SEK", "").split(' ').join(''), 10) : 0;
		pensionAssets += customerState.customer_asset_pinsurance_fund !== "" ? parseInt(customerState.customer_asset_pinsurance_fund.replace(" SEK", "").split(' ').join(''), 10) : 0;
		pensionAssets += customerState.customer_asset_pinsurance_account !== "" ? parseInt(customerState.customer_asset_pinsurance_account.replace(" SEK", "").split(' ').join(''), 10) : 0;

		totalAssets = totalAssets > 0 ? localeNumber(totalAssets, 0) : totalAssets;
		cashAssets = cashAssets > 0 ? localeNumber(cashAssets, 0) : cashAssets;
		pensionAssets = pensionAssets > 0 ? localeNumber(pensionAssets, 0) : pensionAssets;

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Ekonomisk situation</h2>

					<div className="Cob-view4-fields">

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="date"
									value={customerState.date}
									autocomplete="nope"
									label="Datum för rådgivningen"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({date: target.value})}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<Select
									label="Rådgivningens utförande"
									selectedIndex={customerState.meetingFormatIndex}
									options={[
										{label: "Personligt möte", value: "personal"},
										{label: "Telefonmöte", value: "phone"},
										{label: "Videomöte", value: "video"}
									]}
									onChange={(option, meetingFormatIndex) => this.setCustomerState({
										meetingFormat: option.value,
										meetingFormatIndex
									})}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>

								<input type={"hidden"} name="radio_meeting_type"
									   value={customerState.meetingFormat == null ? "" : customerState.meetingFormat}/>
							</div>
						</StackLayout>

						{(customerState.meetingFormat === "phone" || customerState.meetingFormat === "video") && (
							<React.Fragment>
								<strong>Vid rådgivning på distans (samtliga ska uppfyllas)</strong>

								<div style={{flexDirection: "row", marginBottom: "30px", marginTop: "5px"}}>
									<Checkbox
										name={"checkbox_customer_extcheck"}
										label={"Kontroll mot externa register"}
										checked={customerState.checkbox_customer_extcheck === "yes"}
										value={"yes"}
										highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
										onChange={checked => this.setCustomerState({checkbox_customer_extcheck: checked ? "yes" : "no"})}
									/>
									<Checkbox
										name={"checkbox_customer_countercheck"}
										label={"Utfört motkontroll"}
										checked={customerState.checkbox_customer_countercheck === "yes"}
										value={"yes"}
										highlightErrors={hasFormValidationErrors}
                    readOnly={readOnly}
										onChange={checked => this.setCustomerState({checkbox_customer_countercheck: checked ? "yes" : "no"})}
										style={{marginLeft: "40px"}}
									/>
								</div>
							</React.Fragment>
						)}

						{(customerState.meetingFormat !== "phone" && customerState.meetingFormat !== "video") && (
							<React.Fragment>
								<input type={"hidden"} name={"checkbox_customer_extcheck"} value={""} />
								<input type={"hidden"} name={"checkbox_customer_countercheck"} value={""} />
							</React.Fragment>
						)}

						<h3 style={{paddingBottom: "10px"}}>Identitetskontroll</h3>
						<OptionsList
							type="checkbox"
							description="Rådgivaren har tagit del av"
							orientation="horizontal"
							style={{marginBottom: "20px"}}
							value={
								customerState.checkbox_idcheck_id === "yes" ||
								customerState.checkbox_idcheck_drivinglic === "yes" ||
								customerState.checkbox_idcheck_passport === "yes" ||
								customerState.checkbox_idcheck_known === "Ja" ?
									"filled" :
									""
							}
							options={[
								{
									name: "checkbox_idcheck_id",
									label: "ID-kort",
									value: customerState.checkbox_idcheck_id === "yes" ? "yes" : "no",
									checked: customerState.checkbox_idcheck_id === "yes",
									onChange: checked => this.setCustomerState({checkbox_idcheck_id: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_idcheck_drivinglic",
									label: "Körkort",
									value: customerState.checkbox_idcheck_drivinglic === "yes" ? "yes" : "no",
									checked: customerState.checkbox_idcheck_drivinglic === "yes",
									onChange: checked => this.setCustomerState({checkbox_idcheck_drivinglic: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_idcheck_passport",
									label: "Pass",
									value: customerState.checkbox_idcheck_passport === "yes" ? "yes" : "no",
									checked: customerState.checkbox_idcheck_passport === "yes",
									onChange: checked => this.setCustomerState({checkbox_idcheck_passport: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_idcheck_known",
									label: "Känd",
									value: customerState.checkbox_idcheck_known === "Ja" ? "Ja" : "no",
									checked: customerState.checkbox_idcheck_known === "Ja",
									onChange: checked => this.setCustomerState({checkbox_idcheck_known: checked ? "Ja" : "no"})
								}
							]}
							validation={value => (value && value === "filled") || false}
							highlightErrors={hasFormValidationErrors}
              readOnly={readOnly}
						/>

						<div className="h-line spacing"/>

						<h3>Tillgångar</h3>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_asset_bank"
									label="Bankmedel"
									unit=" SEK"
									value={customerState.customer_asset_bank || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_bank: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_lifeinsurance"
									label="Livförsäkring"
									unit=" SEK"
									value={customerState.customer_asset_lifeinsurance || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_lifeinsurance: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_realestate"
									label="Fastighet (marknadsvärde)"
									unit=" SEK"
									value={customerState.customer_asset_realestate || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_realestate: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>

						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth
									 style={{marginTop: "30px"}}>
							<div>
								<NumberField
									name="customer_asset_kinsurance_trad"
									label="Kapitalförsäkring: Trad"
									unit=" SEK"
									value={customerState.customer_asset_kinsurance_trad || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_kinsurance_trad: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_kinsurance_fund"
									label="Kapitalförsäkring: Fond"
									unit=" SEK"
									value={customerState.customer_asset_kinsurance_fund || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_kinsurance_fund: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_kinsurance_account"
									label="Kapitalförsäkring: Depå"
									unit=" SEK"
									value={customerState.customer_asset_kinsurance_account || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_kinsurance_account: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>

							<div>
								<NumberField
									name="customer_asset_pinsurance_trad"
									label="Pensionsförsäkring: Trad"
									unit=" SEK"
									value={customerState.customer_asset_pinsurance_trad || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_pinsurance_trad: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_pinsurance_fund"
									label="Pensionsförsäkring: Fond"
									unit=" SEK"
									value={customerState.customer_asset_pinsurance_fund || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_pinsurance_fund: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_pinsurance_account"
									label="Pensionsförsäkring: Depå"
									unit=" SEK"
									value={customerState.customer_asset_pinsurance_account || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_pinsurance_account: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth
									 style={{marginTop: "30px"}}>
							<div>
								<NumberField
									name="customer_asset_isk_strukt"
									label="ISK: Strukt"
									unit=" SEK"
									value={customerState.customer_asset_isk_strukt || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_isk_strukt: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_isk_stockfund"
									label="ISK: Aktier/fonder"
									unit=" SEK"
									value={customerState.customer_asset_isk_stockfund || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_isk_stockfund: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_asset_account_strukt"
									label="Vanlig depå (naket): Strukt"
									unit=" SEK"
									value={customerState.customer_asset_account_strukt || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_account_strukt: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
							<div>
								<NumberField
									name="customer_asset_account_stockfund"
									label="Vanlig depå (naket): Aktier/fonder"
									unit=" SEK"
									value={customerState.customer_asset_account_stockfund || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_account_stockfund: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth
									 style={{marginTop: "30px"}}>
							<div>
								<NumberField
									name="customer_asset_other"
									label="Övriga tillgångar"
									unit=" SEK"
									value={customerState.customer_asset_other || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_asset_other: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
                  readOnly={readOnly}
								/>
							</div>
						</StackLayout>

						<div className="Cob-TableSummary" style={{marginTop: "30px"}}>
							<div className="Cob-TableSummary-container">
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">Totala tillgångar</div>
									<div className="Cob-TableSummaryField-value">{totalAssets} SEK</div>
								</div>
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">
										Placeringsbara tillgångar (inte k-trad, p-förs, fast- eller lån)
									</div>
									<div className="Cob-TableSummaryField-value">{cashAssets} SEK</div>
								</div>
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">
										Pensionstillgångar (alla pensionsförsäkringar)
									</div>
									<div className="Cob-TableSummaryField-value">{pensionAssets} SEK</div>
								</div>
							</div>
						</div>

						<input type="hidden" name="customer_asset_total" value={totalAssets + " SEK"}/>
						<input type="hidden" name="customer_asset_total_cash" value={cashAssets + " SEK"}/>
						<input type="hidden" name="customer_asset_total_pension" value={pensionAssets + " SEK"}/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors, readOnly} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
  newState.readOnly = readOnly;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		customerState: {
			date: customer.getData("date") || "",
			meetingFormat: customer.getData("radio_meeting_type", null),

			meetingFormatIndex: (
				customer.getData("radio_meeting_type") === "personal" ? 0 :
					customer.getData("radio_meeting_type") === "phone" ? 1 :
						customer.getData("radio_meeting_type") === "video" ? 2 :
							-1),

			customer_asset_bank: customer.getData("customer_asset_bank") || "0",
			customer_asset_lifeinsurance: customer.getData("customer_asset_lifeinsurance") || "0",
			customer_asset_realestate: customer.getData("customer_asset_realestate") || "0",

			customer_asset_kinsurance_trad: customer.getData("customer_asset_kinsurance_trad") || "0",
			customer_asset_kinsurance_fund: customer.getData("customer_asset_kinsurance_fund") || "0",
			customer_asset_kinsurance_account: customer.getData("customer_asset_kinsurance_account") || "0",

			customer_asset_pinsurance_trad: customer.getData("customer_asset_pinsurance_trad") || "0",
			customer_asset_pinsurance_fund: customer.getData("customer_asset_pinsurance_fund") || "0",
			customer_asset_pinsurance_account: customer.getData("customer_asset_pinsurance_account") || "0",

			customer_asset_isk_strukt: customer.getData("customer_asset_isk_strukt") || "0",
			customer_asset_isk_stockfund: customer.getData("customer_asset_isk_stockfund") || "0",
			customer_asset_account_strukt: customer.getData("customer_asset_account_strukt") || "0",
			customer_asset_account_stockfund: customer.getData("customer_asset_account_stockfund") || "0",
			customer_asset_other: customer.getData("customer_asset_other") || "0",

			checkbox_idcheck_id: customer.getData("checkbox_idcheck_id") ? "yes" : "no",
			checkbox_idcheck_drivinglic: customer.getData("checkbox_idcheck_drivinglic") ? "yes" : "no",
			checkbox_idcheck_passport: customer.getData("checkbox_idcheck_passport") ? "yes" : "no",
			checkbox_idcheck_known: customer.getData("checkbox_idcheck_known") ? "Ja" : "no",

			checkbox_customer_extcheck: customer.getData("checkbox_customer_extcheck", ""),
			checkbox_customer_countercheck: customer.getData("checkbox_customer_countercheck", ""),
		}
	};

	if (newState.customerState.date === "")
	{
		const today = new Date();
		let dayDate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
		let monthDate = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		newState.customerState.date = today.getFullYear() + '-' + monthDate + '-' + dayDate;
	}

	newState.isFulfilled = false;
	return newState;
}

function _getFields()
{
	return [
		"date",
		"radio_meeting_type",
		"customer_asset_bank",
		"customer_asset_lifeinsurance",
		"customer_asset_realestate",

		"customer_asset_kinsurance_trad",
		"customer_asset_kinsurance_fund",
		"customer_asset_kinsurance_account",

		"customer_asset_pinsurance_trad",
		"customer_asset_pinsurance_fund",
		"customer_asset_pinsurance_account",

		"customer_asset_isk_strukt",
		"customer_asset_isk_stockfund",
		"customer_asset_account_strukt",
		"customer_asset_account_stockfund",
		"customer_asset_other"
	];
}